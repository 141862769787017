// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tree-select .ant-select-clear {
  display: none !important;
}

.custom-tree-select .ant-select-selector:focus,
.custom-tree-select .ant-select-selector:active,
.custom-tree-select .ant-select-selector-focused {
  border-color: #000000 !important; /* Your custom focus border color */
}

.custom-tree-select .ant-select-selector {
  border-radius: 0px !important; /* Your custom border radius */
}

.custom-dropdown {
  max-height: 400px;
  overflow: auto;
  background-color: #f0f0f0;
}

.custom-tree-select .ant-select-arrow {
  display: none;
}

.custom-tree-select-wrapper {
  position: relative;
}

.custom-tree-select-wrapper .custom-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: opacity 0.2s;
}

.custom-tree-select-wrapper .ant-select-selector:focus + .custom-arrow,
.custom-tree-select-wrapper .ant-select-selector-focused + .custom-arrow {
  opacity: 0;
}

.hoverable-text {
  cursor: pointer;
  color: #0000EE;
  font-weight: bold;
  text-decoration: none;
  transition: -webkit-text-decoration 0.2s ease-in-out;
  transition: text-decoration 0.2s ease-in-out;
  transition: text-decoration 0.2s ease-in-out, -webkit-text-decoration 0.2s ease-in-out; /* Optional for smoothness */
}

.hoverable-text:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/UIComponents/customStyles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;;;EAGE,gCAAgC,EAAE,mCAAmC;AACvE;;AAEA;EACE,6BAA6B,EAAE,8BAA8B;AAC/D;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;EAC3B,oBAAoB;EACpB,wBAAwB;AAC1B;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,oDAA4C;EAA5C,4CAA4C;EAA5C,sFAA4C,EAAE,4BAA4B;AAC5E;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".custom-tree-select .ant-select-clear {\r\n  display: none !important;\r\n}\r\n\r\n.custom-tree-select .ant-select-selector:focus,\r\n.custom-tree-select .ant-select-selector:active,\r\n.custom-tree-select .ant-select-selector-focused {\r\n  border-color: #000000 !important; /* Your custom focus border color */\r\n}\r\n\r\n.custom-tree-select .ant-select-selector {\r\n  border-radius: 0px !important; /* Your custom border radius */\r\n}\r\n\r\n.custom-dropdown {\r\n  max-height: 400px;\r\n  overflow: auto;\r\n  background-color: #f0f0f0;\r\n}\r\n\r\n.custom-tree-select .ant-select-arrow {\r\n  display: none;\r\n}\r\n\r\n.custom-tree-select-wrapper {\r\n  position: relative;\r\n}\r\n\r\n.custom-tree-select-wrapper .custom-arrow {\r\n  position: absolute;\r\n  right: 10px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n  pointer-events: none;\r\n  transition: opacity 0.2s;\r\n}\r\n\r\n.custom-tree-select-wrapper .ant-select-selector:focus + .custom-arrow,\r\n.custom-tree-select-wrapper .ant-select-selector-focused + .custom-arrow {\r\n  opacity: 0;\r\n}\r\n\r\n.hoverable-text {\r\n  cursor: pointer;\r\n  color: #0000EE;\r\n  font-weight: bold;\r\n  text-decoration: none;\r\n  transition: text-decoration 0.2s ease-in-out; /* Optional for smoothness */\r\n}\r\n\r\n.hoverable-text:hover {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
