import { Typography, Box, Divider } from '@mui/material';
import { ErganiFields } from '../../helperFuncs';
import { useWindowSize } from '@uidotdev/usehooks';

export default function ErganiDetails(props) {
  const { erganiHistory } = props;
  const { width } = useWindowSize();
  const isMobileView = width < 1023;
  const isIPadView = width >= 1024 && width <= 1224;
  const isLaptopView = width >= 1225 && width <= 1540;

  const gridColumnWidth = () => {
    let gridTemplateColumns = '550px 1fr';
    if (isMobileView) {
      gridTemplateColumns = '250px 1fr';
    } else if (isLaptopView) {
      gridTemplateColumns = '410px 1fr';
    } else if (isIPadView) {
      gridTemplateColumns = '210px 1fr';
    } else {
      gridTemplateColumns = '550px 1fr';
    }

    return gridTemplateColumns;
  };

  const DetailRow = ({ label, value }) => (
    <>
      <Box
        display='grid'
        gridTemplateColumns={gridColumnWidth()}
        alignItems='center'
        my={1}
      >
        <Typography fontWeight={600}>{label}</Typography>
        <Typography>{value}</Typography>
      </Box>
      <Divider />
    </>
  );

  return (
    <>
      {erganiHistory.length > 0 ? (
        erganiHistory.map((row) => (
          <Box key={`detail-${Math.random()}`} mb={4}>
            {ErganiFields.map((field) => (
              <DetailRow
                key={field.key}
                label={field.label}
                value={row[field.key]}
              />
            ))}
          </Box>
        ))
      ) : (
        <Typography>Δεν βρέθηκαν στοιχεία από το ΕΡΓΑΝΗ</Typography>
      )}
    </>
  );
}
