import { getRequestByAfm, isConsent } from '../../Services/httpCalls';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../Services/useAuth';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { jwtDecode } from 'jwt-decode';

export function useIsConsent(consent) {
  const { token } = useAuth();
  const navigate = useNavigate();

  const handleNavigation = (isNewUser, isConesent, consentData) => {
    if (!isConesent || !consentData.is_user_certified) {
      navigate('/registration-form');
      return;
    }

    if (isNewUser) {
      navigate('/request');
    } else {
      navigate('/registry-review');
    }
  };

  return useMutation({
    mutationKey: [`isConsent`],
    mutationFn: async () => {
      return await isConsent(consent);
    },
    onSuccess: async (response) => {
      try {
        const resp = await getRequestByAfm(jwtDecode(token)?.afm);
        const isNewUser = !(resp?.data?.requests?.length > 0);
        handleNavigation(isNewUser, consent, response.data);

        enqueueSnackbar(response.data.message, {
          variant: 'info',
          autoHideDuration: 2000,
        });
      } catch (error) {
        enqueueSnackbar('Παρουσιάστηκε σφάλμα.', {
          variant: 'error',
          autoHideDuration: 2000,
        });
      }
    },
    onError: (error) => {
      enqueueSnackbar('Παρουσιάστηκε σφάλμα.', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
}
