import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { putAnswer } from './httpCalls';

export function usePutAnswer(data) {
  return useMutation({
    mutationKey: [`answer`],
    mutationFn: async (body) => {
      return await putAnswer(data);
    },
    onSuccess: () => {
      enqueueSnackbar('Επιτυχία', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.message || 'Αποτυχία', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
}
