import { Box, Typography } from '@mui/material';
import Details from '../../Details/Details/Details';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Services/useAuth';
import { getRequestByAfm } from '../../Services/httpCalls';
import { jwtDecode } from 'jwt-decode';
import GovButton from '../../UIComponents/Buttons/GovButton';

const DetailsPreview = () => {
  const { token, getUser } = useAuth();
  const navigate = useNavigate();

  const handleConfirmationClick = async () => {
    try {
      const resp = await getRequestByAfm(getUser()?.afm);
      const isNewUser = !(resp?.data?.requests?.length > 0);

      if (isNewUser) {
        navigate('/confirm');
      } else {
        navigate('/registry-review');
      }
    } catch (error) {
      if (
        error.response.data.statusCode === 402 ||
        error.response.data.statusCode === 403
      ) {
        getUser()?.is_admin
          ? navigate('/admin-error')
          : navigate('/user-error');
        console.error('Error determining if user is new:', error);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', mt: 2 }}>
      <Typography variant='body1'>Πριν ξεκινήσουμε</Typography>
      <Typography variant='h1'>
        Είναι τα προσωπικά σας στοιχεία σωστά;
      </Typography>
      <Typography sx={{ my: 2 }}>
        Πριν συμπληρώσετε τη δήλωση, παρακαλούμε να ελέγξετε τα στοιχεία της
        ταυτότητάς σας.
      </Typography>

      <Details
        rows={[
          { name: 'Όνομα', value: getUser()?.first_name },
          { name: 'Επώνυμο', value: getUser()?.last_name },
          { name: 'ΑΦΜ', value: getUser()?.afm },
          { name: 'Όνομα Πατέρα', value: getUser()?.fathers_name },
        ]}
      />
      <Box sx={{ my: 4 }}>
        <GovButton
          ariaLabel={'Επιβεβαίωση'}
          type={'submit'}
          variant='contained'
          onClick={handleConfirmationClick}
          background={'#006736'}
          hoverBackground={'#154a2b'}
          borderColor={'#006736'}
        >
          Επιβεβαίωση
        </GovButton>
      </Box>
    </Box>
  );
};

export default DetailsPreview;
