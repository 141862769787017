import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { postNewRequest } from './httpCalls';
import { useNavigate } from 'react-router-dom';

export function usePostNewRequest(data) {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [`new_request`],
    mutationFn: async () => {
      const resp = await postNewRequest(data);
      return resp;
    },
    onSuccess: (resp) => {
      navigate(`/request-review/${resp.data.request_id}/success-new`);
    },
    onError: (resp) => {
      enqueueSnackbar(resp?.response?.data?.message || 'Κάτι πήγε στραβά', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
}
