import GovWrapper from '../../UIComponents/GovWrapper';
import { Typography } from '@mui/material';
import MultiTreeSelect from '../../UIComponents/MultiTreeSelect';

const MunicipalitySelect = ({
  data,
  selectedMunicipalities,
  onChange,
  isSubmited,
}) => {
  return (
    <>
      <Typography variant='h3' sx={{ my: 4 }}>
        Χωροταξική Δραστηριοποίηση
      </Typography>
      <GovWrapper
        label={'Επιλέξτε έναν ή παραπάνω δήμους δραστηριοποίησης*'}
        width={'100%'}
        id='spatialActivity'
      >
        <>
          <MultiTreeSelect
            id='spatialActivity'
            data={data}
            name={'spatialActivity'}
            onChange={onChange}
            value={selectedMunicipalities}
            error={selectedMunicipalities?.length === 0 && isSubmited}
          />
          {selectedMunicipalities?.length === 0 && isSubmited ? (
            <Typography
              aria-label='muncipality'
              sx={{
                fontFamily:
                  'Twemoji Country Flags, Roboto, Helvetica, Arial, sans-serif',
                fontSize: '12px',
              }}
              color={'#ca2e2e'}
            >
              Επιλέξτε τουλάχιστον ένα δήμο δραστηριοποίησης
            </Typography>
          ) : null}
        </>
      </GovWrapper>
    </>
  );
};

export default MunicipalitySelect;
