import { Box } from '@mui/material';
import GovWrapper from '../../GovWrapper';
import { TextFieldElement, CheckboxElement } from 'react-hook-form-mui';
import { useWindowSize } from '@uidotdev/usehooks';
import useAuth from '../../../Services/useAuth';

export default function AdminForm(props) {
  const { editUserId } = props;
  const { width } = useWindowSize();
  const { getUser } = useAuth();
  const isMobileView = width < 1023;

  const nameInputStyle = () => {
    let style = {
      padding: 0,
      borderRadius: 0,
    };
    if (isMobileView) {
      return { ...style, minWidth: 100 };
    } else {
      return { ...style, minWidth: 200 };
    }
  };
  return (
    <Box>
      <GovWrapper label={'Ονομα'} width={'100%'} id='first_name'>
        <TextFieldElement id='first_name' name='first_name' />
      </GovWrapper>
      <GovWrapper label={'Επώνυμο'} width={'100%'} id='last_name'>
        <TextFieldElement
          id='last_name'
          name='last_name'
          InputProps={{
            style: nameInputStyle(),
          }}
        />
      </GovWrapper>
      <GovWrapper label={'ΑΦΜ'} width={'100%'} id='afm'>
        <TextFieldElement
          id='afm'
          name='afm'
          type='number'
          InputProps={{
            style: nameInputStyle(),
          }}
        />
      </GovWrapper>
      <GovWrapper label={'Ονομα Πατέρα'} width={'100%'} id='fathers_name'>
        <TextFieldElement
          id='fathers_name'
          name='fathers_name'
          InputProps={{
            style: nameInputStyle(),
          }}
        />
      </GovWrapper>
      <GovWrapper label={'Ονομα Μητέρας'} width={'100%'} id='mothers_name'>
        <TextFieldElement
          id='mothers_name'
          name='mothers_name'
          InputProps={{
            style: nameInputStyle(),
          }}
        />
      </GovWrapper>
      <GovWrapper label={'Email'} width={'100%'} id='email'>
        <TextFieldElement
          id='email'
          name='email'
          InputProps={{
            style: nameInputStyle(),
          }}
        />
      </GovWrapper>

      <Box mt={2}>
        <CheckboxElement
          id='is_super_admin'
          label={'Super Admin'}
          name={'is_super_admin'}
          key={'is_super_admin'}
          disabled={editUserId === getUser().sub}
        />
      </Box>
    </Box>
  );
}
