import { Button } from '@mui/material';

export default function GovButton({
  children,
  onClick,
  background,
  hoverBackground,
  ariaLabel,
  type,
  color,
  variant,
  borderColor,
  startIcon,
}) {
  return (
    <Button
      variant={variant}
      sx={{
        backgroundColor: background,
        color: color,
        padding: '0.7rem 1.20rem',
        borderColor: borderColor,
        border: `1px solid ${borderColor}`,
        fontWeight: 500,
        borderRadius: '5px',
        fontSize: '16px',
        width: 'inherit',
        '&:hover': {
          backgroundColor: hoverBackground,
          color: color,
          border: `1px solid ${borderColor}`,
        },
      }}
      aria-label={ariaLabel}
      onClick={onClick}
      type={type}
      {...(startIcon && { startIcon: startIcon })}
    >
      {children}
    </Button>
  );
}
