import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { logoutUser } from './httpCalls';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function useAuth() {
  const [role, setRole] = useLocalStorage('role', '');
  const [token, setToken] = useLocalStorage('token', '');
  const navigate = useNavigate();

  // Check if token is expired
  const isTokenExpired = () => {
    if (!token) return true; // No token = treat as expired
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return true; // If the token is invalid, treat as expired
    }
  };

  const handleTokenExpiration = (status) => {
    if (isTokenExpired() && status === 401) {
      logout();
      navigate('/');
    }
  };

  const logout = async () => {
    try {
      const decodedToken = getUser();
      if (decodedToken) {
        await logoutUser(decodedToken?.sub);
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const getRole = () => {
    try {
      const decodedToken = getUser();
      if (!decodedToken) return '';
      return decodedToken?.is_admin === 1 ? 'admin' : 'user';
    } catch (error) {
      console.error('Invalid token:', error);
      return '';
    }
  };

  const getUser = () => {
    if (!token) return null;

    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Invalid token:', error);
      return null;
    }
  };

  return {
    role,
    setRole,
    logout,
    getRole,
    token,
    setToken,
    getUser,
    handleTokenExpiration,
  };
}

export default useAuth;
