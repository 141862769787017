import { Box, Grid, Typography } from '@mui/material';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { useNavigate } from 'react-router-dom';

const ProtectedRouteViolation = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          maxWidth: '42rem',
          fontSize: '3rem',
          lineHeight: '1.25',
          mh: '3rem',
          fontWeight: '700',
        }}
      >
        <Typography sx={{ my: 4 }} variant='h1'>
          Απαγόρευση πρόσβασης
        </Typography>
      </Box>
      <Typography sx={{ my: 4 }} variant='h3'>
        Δεν έχετε πρόσβαση στο περιεχόμενο αυτής της σελίδας.
      </Typography>
      <Grid display='flex' justifyContent='flex-start'>
        <Grid item xl={5}>
          <GovButton
            ariaLabel={'Επιστροφή'}
            type={'button'}
            variant='contained'
            background={'#003476'}
            borderColor={'#003476'}
            hoverBackground={'#02234e'}
            onClick={() => navigate(-1)}
          >
            Επιστροφή
          </GovButton>
        </Grid>
      </Grid>
    </>
  );
};
export default ProtectedRouteViolation;
