import { Box, Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

export default function SuccessSnackBar({ open, handleClose, message }) {
    return (
        <div>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Box sx={{zIndex:'1000', bgcolor: 'white.main', width: '700px', height: ' 170px', gap: 3, display: 'flex', flexDirection: 'column', border:'5px solid', borderColor:'success.main' }}>
                <Typography sx={{bgcolor:'success.main', color:'white.main',  px:2, py:1,}} variant='h4'>Επιτυχία</Typography>

                    <Typography variant='h3' sx={{px:3}}>{message}</Typography>
                    <Typography  sx={{px:3}}>Επικοινωνήστε στο <a style={{ color: 'green' }} href="https://example.gov.gr/"> example@gov.gr </a>
                        αν υπάρχει κάποιο πρόβλημα</Typography>


                </Box>

            </Snackbar>
        </div>
    );
}