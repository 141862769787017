import { Box, Typography } from '@mui/material';
import logoPol from '../assets/logoPol.png';
import { Link } from 'react-router-dom';
import grnetLogo from '../assets/grnetLogo.svg';
const Footer = () => {
  return (
    <>
      <Box
        sx={{
          py: '1rem',
          borderTop: '4px solid',
          borderColor: 'link.secondary',
          backgroundColor: 'gray.footer',
          width: '100%',
        }}
      >
        <Box
          sx={{
            px: '15px',
            display: 'flex',
            height: '72px',
            py: '14px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span style={{ fontSize: '14px', color: '#5e636e' }}>
            © Powered by Knowledge AE - 01 SOLUTIONS HELLAS Ε.Π.Ε 2024 για το
            <Typography
              component={Link}
              sx={{ fontSize: '14px', color: 'link.main', px: 0.5 }}
              to='https://www.culture.gov.gr/'
            >
              Υπουργείο Πολιτισμού
            </Typography>
          </span>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <img height='50px' src={logoPol} alt='' />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Footer;
