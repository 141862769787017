import { Box, Typography } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequestByAfm } from "../../Services/httpCalls";
import useAuth from "../../Services/useAuth";

const RequestHistorySide = () => {
  const { token } = useAuth();
  const [isNewUser, setIsNewUser] = useState(true); // Default to true or false depending on your preference

  useEffect(() => {
    const checkIsNewUser = async () => {
        if (!token) return false;
        try {
          const resp = await getRequestByAfm(jwtDecode(token)?.afm); 
          setIsNewUser(!(resp?.data?.requests?.length > 0)); 
        } catch (error) {
          console.error("Invalid token:", error);
          return false;
        
      };
    };
    checkIsNewUser();
  },[]);


  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, justifyContent: 'center', flexDirection: 'column', px: 6 }}>
      <Typography sx={{ my: 4, fontSize: '30px', borderBottom: '1px solid black' }}>Δείτε Επίσης</Typography>
      {!isNewUser && (
        <Typography component={Link} to="/registry-review" sx={{ color: 'link.main', px: 0.5 }}>
          Στοιχεία μητρώου
        </Typography>
      )}
    </Box>
  );
};

export default RequestHistorySide;
