export const newReqDefaultValues = (person) => {
  return {
    name: person?.first_name,
    surname: person?.last_name,
    mothersName: person?.mothers_name,
    email:person?.email || 'example@gmail.com',
    fathersName: person?.fathers_name,
    afm: person?.afm,
    profesionalData: [{ profession_id: "", profession_proof_id: "" }],
  };
};
export const modReqDefaultValues = (request) => {
  return {
    name: request?.user?.first_name,
    surname: request?.user?.last_name,
    mothersName: request?.user?.mothers_name,
    fathersName: request?.user?.fathers_name,
    afm: request?.user?.afm,
    amka: request?.amka,
    email: request?.user?.email || '',
    phone: request?.phone_number,
    lastSubscription: request?.last_updated,
    certifiedUser: request?.user?.is_certified,
    protocolNumber: request?.protocol,
    spatialActivity:request?.municipalities.map(municipality => municipality.id),
    ...request.initialComGroupsValues,
  };
};
