
import { Typography } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import GovWrapper from "../../UIComponents/GovWrapper";


const ContactInfoSection = ({ defaultEmail }) => {
  return (
    <>
      <Typography variant="h3" sx={{ my: 4 }}>
        Στοιχεία Επικοινωνίας
      </Typography>
      <GovWrapper label={"Τηλέφωνο*"} id="phone" width={"100%"}>
        <TextFieldElement fullWidth name="phone" id="phone" />
      </GovWrapper>
      <GovWrapper
        id="email"
        label={"Δ/νση Ηλεκτρ. Ταχυδρομείου(E-mail)*"}
        width={"100%"}
        disabled={true}
      >
        <TextFieldElement
          type="email"
          fullWidth
          name="email"
          id="email"
          defaultValue={defaultEmail}
        />
      </GovWrapper>
    </>
  );
};

export default ContactInfoSection;
