import { Typography } from "@mui/material";
import useAuth from "../../Services/useAuth";
import GovWrapper from "../../UIComponents/GovWrapper";
import { TextFieldElement } from "react-hook-form-mui";


const PersonalInfoSection = () => {
  const { getUser } = useAuth();
  return (
    <>
          <Typography variant="h3" sx={{ mt: 4 }}>
          Προσωπικά Στοιχεία
      </Typography>
      <GovWrapper
            label={"Όνομα*"}
            id="name"
            disabled={true}
            defaultValue={getUser()?.firstname}
            width={"100%"}
          >
            <TextFieldElement sx={{color:'black'}} id="name" name="name" />
          </GovWrapper>
          <GovWrapper
            label={"Επώνυμο*"}
            id="surname"
            disabled={true}
            defaultValue={getUser()?.lastname}
            width={"100%"}
          >
            <TextFieldElement fullWidth id="surname" name="surname" />
          </GovWrapper>
          <GovWrapper
            label={"Όνομα Πατέρα*"}
            id="fathersName"
            disabled={true}
            defaultValue={getUser()?.fathername}
            width={"100%"}
          >
            <TextFieldElement fullWidth id="fathersName" name="fathersName" />
          </GovWrapper>
          <GovWrapper
            label={"Όνομα Μητέρας*"}
            id="mothersName"
            disabled={true}
            defaultValue={getUser()?.mothersName}
            width={"100%"}
          >
            <TextFieldElement fullWidth id="mothersName" name="mothersName" />
          </GovWrapper>
          <GovWrapper
            label={"Α.Φ.Μ*"}
            id="afm"
            disabled={true}
            defaultValue={getUser()?.afm}
            width={"100%"}
          >
            <TextFieldElement fullWidth name="afm" id="afm" />
          </GovWrapper>
          <GovWrapper label={"AMKA*"} id="amka" width={"100%"}>
            <TextFieldElement fullWidth name="amka" id="amka" />
          </GovWrapper>
    </>
  );
};

export default PersonalInfoSection;
