import { Box, Grid, Typography } from '@mui/material';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { useNavigate } from 'react-router-dom';

export default function SessionExpirePage() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          maxWidth: '42rem',
          fontSize: '3rem',
          lineHeight: '1.25',
          mh: '3rem',
          fontWeight: '700',
        }}
      >
        <Typography sx={{ my: 4 }} variant='h1'>
          Λήξη συνεδρίας
        </Typography>
      </Box>
      <Typography sx={{ my: 4 }} variant='h3'>
        Η συνεδρία σας στη εφαρμογή έχει λήξει. Παρακαλούμε συνδεθείτε ξανά για
        να συνεχίσετε.
      </Typography>
      <Grid display='flex' justifyContent='flex-start'>
        <Grid item xl={5}>
          <GovButton
            ariaLabel={'Έξοδος'}
            type={'button'}
            variant='contained'
            background={'#003476'}
            borderColor={'#003476'}
            hoverBackground={'#02234e'}
            onClick={() => navigate('/')}
          >
            Έξοδος
          </GovButton>
        </Grid>
      </Grid>
    </>
  );
}
