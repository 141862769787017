import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import React from 'react';
import { formatDateFNS } from '../../helperFuncs';

export const mapByRefValue = (objectWithRefValues, refDetails) => {
  const _refDetails = refDetails.map((item, index) => {
    if (!item.refValue) return item;
    return {
      ...item,
      id: index,
      value: objectWithRefValues[item?.refValue],
    };
  });
  return _refDetails;
};

export const defaultRenderArray = (array) => {
  return array.join(', ');
};

function Details(props) {
  const defaultRenderDates = (date) => {
    return formatDateFNS(date);
  };

  const renderAsRows = (rows) => {
    const renderedRows = [];

    rows.forEach((row, index) => {
      // Render the header row
      renderedRows.push(
        <TableRow
          key={`${JSON.stringify(row.name)}-header`}
          sx={{
            px: 0,
            mt: 5,

            backgroundColor: renderAsStripped ? (index % 2 === 0 ? "#eeeeee" : "") : "",
          }}
        >
          {renderCell(row.name, renderFirstRowsAsHeader, index, true)}
        </TableRow>
      );

      // Render the value row
      renderedRows.push(
        <TableRow
          key={`${JSON.stringify(row.name)}-value`}
          sx={{
            mt: 5,
            px: 0,
            backgroundColor: renderAsStripped ? (index % 2 === 0 ? "#eeeeee" : "") : "",
          }}
        >
          {renderArraysAsColumns && Array.isArray(row.value)
            ? renderValuesAsColumns(row, index)
            : renderCell(row.value, renderFirstRowsAsHeader, index)}
        </TableRow>
      );
    });

    return renderedRows;
  };

  const calculateMaxNumberOfColumns = () => {
    const rows = props?.rows;
    if (!rows || !Array.isArray(rows)) return 0;
    const maxNumberOfColumns = rows.reduce((max, row) => {
      return Math.max(max, row?.value?.length);
    }, 0);
    return maxNumberOfColumns;
  };


  const { rows = [],
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    style, // this is unused
    renderArraysAsColumns = false,
    renderAsStripped = false,
    isRenderAsRows = false,
    renderFirstRowsAsHeader = false,
    removeLines = false,
    renderDates = defaultRenderDates,
    renderArrays = defaultRenderArray
  } = props;

  const maxNumberOfColumns = useMemo(() =>
    calculateMaxNumberOfColumns(), [renderArraysAsColumns, rows.length]);


  const renderValue = (value) => value instanceof Date
    ? renderDates(value)
    : Array.isArray(value)
      ? renderArrays(value)
      : (typeof value === 'object' && value !== null) && 'url' in value
        ? value.label
        : value;

  const renderCell = (
    value, renderFirstRowsAsHeader, index, renderBold
    // eslint-disable-next-line max-params
  ) => {
    return <TableCell
      sx={{ px: 0, }}
      variant={index == 0
        ? 'head'
        : 'body'}
      scope='row'
      align='left'
      key={value} >
      {(typeof value === 'object' && value !== null)
        && 'url' in value
        ? 'externalLink' in value
          ? <a
            href={renderValue(value)}
            target='_blank'
            rel='noreferrer'>
            {renderValue(value)}
          </a>
          : <Link fontWeight={renderFirstRowsAsHeader
            && index === 0
            ? '650'
            : '500'}>{renderValue(value)}</Link>
        : <Typography
          fontWeight={renderFirstRowsAsHeader
            ? index === 0
              ? '650'
              : '500'
            : renderBold
              ? 'bold'
              : 'regular'}>{renderValue(value)}</Typography>
      }

    </TableCell >;
  };

  const renderEmptyCells = (numberOfCells) => [...Array.from(Array(numberOfCells).keys())].map((x) => <TableCell align='left' key={x}>
    <Typography>
    </Typography>
  </TableCell>);

  const renderValuesAsColumns = (row, indexRow) =>
    row.value.map((value, index) => {
      return index < row.value.length
        ? renderCell(value, renderFirstRowsAsHeader, indexRow)
        : renderEmptyCells(maxNumberOfColumns - row.value.length);
    });


  const renderRow = (row, index,) => {
    const isInMobileView = window.innerWidth < 600;
    const key = `${row.name}-${index}`;


    return isInMobileView && !renderArraysAsColumns
      ? <Box key={key}>
        <TableRow
          key={key}
          sx={{
            px: 0,
            width: '10000px',
            display: 'table-row',
          }}
        >
          {renderCell(row.name, renderFirstRowsAsHeader, index, true)}

        </TableRow>
        <TableRow
          key={key}
          sx={{
            px: 0,
            width: '10000px',
            display: 'table-row',
          }}
        >
          {renderArraysAsColumns && Array.isArray(row.value)
            ? renderValuesAsColumns(row, index)
            : renderCell(row.value, renderFirstRowsAsHeader, index)
          }
        </TableRow>
      </Box>
      : 
        <TableRow
          key={key}
          sx={{
            px: 0,
            backgroundColor: renderAsStripped
              ? index % 2 === 0
                ? '#eeeeee'
                : ''
              : ''
          }}
        >
          {renderCell(row.name, renderFirstRowsAsHeader, index, true)}
          {renderArraysAsColumns && Array.isArray(row.value)
            ? renderValuesAsColumns(row, index)
            : renderCell(row.value, renderFirstRowsAsHeader, index)
          }

        </TableRow>
     
  };

  const renderRows = (rows) => rows.map((row, index) => {

    return row.isHiddenRow
      ? null
      : renderRow(row, index);
  });


  return (<div >
    <Box
      display='flex'
      flexDirection='row'>
      {title && <Typography variant='h3'>
        {title }
      </Typography>}
      <TableContainer id='table-details'>
        <Table
        key='table'
          size='small' aria-label='simple table'
          sx={{
            tableLayout: 'fixed',
            [`& .${tableCellClasses.root}`]: {
              borderBottom: removeLines && 'none'
            }
          }}>
          <TableBody>
            {isRenderAsRows ? renderAsRows(rows) : renderRows(rows)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </div>);
}

export default Details;
