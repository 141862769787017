import { otpConfirm } from './httpCalls';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export function useOtpConfirm() {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [`new_otp`],
    mutationFn: async (body) => {
      return await otpConfirm(body);
    },
    onSuccess: (response) => {
      enqueueSnackbar('Το email σας επιβεβαιώθηκε!', {
        variant: 'success',
        autoHideDuration: 4000,
      });
      navigate('/details-preview');
    },
    onError: (error) => {
      enqueueSnackbar('Λάθος Κωδικός', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    },
  });
}
