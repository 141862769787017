import { Box, Typography } from "@mui/material"

const SuccessCard = ({ title, children, ...rest }) => { // Notice the curly braces around props
    return (
        <Box sx={{ ...rest.sx, display: 'flex', flexDirection: 'column', justifyContent:'center', gap:3, textAlign:'center', p:2, alignItems:'center' }}>
            <Typography variant="h2">{title}</Typography>
            {children}
        </Box>
    )
}
export default SuccessCard
