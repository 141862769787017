import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { postEmail } from './httpCalls';
import { useNavigate } from 'react-router-dom';

export function usePostEmail() {
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [`post_mail`],
    mutationFn: async (body) => {
      return await postEmail(body);
    },
    onSuccess: () => {
      enqueueSnackbar('To email καταχωρήθηκε!', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      navigate('/confirmation-code');
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.message || 'Αποτυχία', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    },
  });
}
