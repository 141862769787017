import { Box } from "@mui/material";
import AppModal from "./AppModal";
import RoundedButton from "../Buttons/RoundedButton";

const SaveModal = (props) => {
  return (
    <AppModal onHideModal={() => props.setIsShownSaveModal(false)}>
      <Box sx={{ zIndex: "1000" }}>
        <h4>{props.title}</h4>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 6,
          }}
        >
          <RoundedButton
            variant={"text"}
            color="black"
            onClick={() => props.setIsShownSaveModal(false)}
          >
            Όχι
          </RoundedButton>
          <RoundedButton
            color="primary"
            onClick={props.onSave}
            type="submit"
            variant="contained"
          >
            Ναι
          </RoundedButton>
        </Box>
      </Box>
    </AppModal>
  );
};
export default SaveModal;
