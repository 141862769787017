import { Box, Typography, ListItem, List } from "@mui/material";

const UnAuthorizedAdminPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 3,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant="h2">
        Οι κωδικοί σύνδεσης στο Taxisnet που χρησιμοποιήσατε δεν αντιστοιχούν σε
        εξουσιοδοτημένο χρήστη
      </Typography>
      <Typography variant="h3">
        Οι κωδικοί θα πρέπει να ανήκουν σε υπάλληλο δημόσιου φορέα που ανήκει
        στην λίστα εξουσιοδοτημένων φορέων του υπουργείου πολιτισμού
      </Typography>
      <Typography>Σφάλμα κατά τη σύνδεσή σας.(access_denied)</Typography>
      <Typography>
        Σε περίπτωση που το παραπάνω ΑΦΜ αντιστοιχεί σε εξουσιοδοτημένο χρήστη
        και παρόλα αυτά, λαμβάνετε αυτή την απάντηση, επικοινωνήστε με το
        γραφείο υποστήριξης του προγράμματος, αναφέροντας:
      </Typography>
      <List sx={{ listStyleType: "disc", ml: 3 }}>
        <ListItem sx={{ display: "list-item" }}>το ΑΦΜ σας</ListItem>
        <ListItem sx={{ display: "list-item" }}>
          το συγκεκριμένο πρόβλημα
        </ListItem>
      </List>
    </Box>
  );
};
export default UnAuthorizedAdminPage;
