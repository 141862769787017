import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { deleteFile } from "./httpCalls";

export function useDeleteFile(file) {
    return useMutation({
        mutationKey: [`delete_file`],
        mutationFn: async (file) => {
            return await deleteFile(file)
        },
        onSuccess: () => {
            enqueueSnackbar('Επιτυχία', { variant: 'success', autoHideDuration:2000 })
        },
        onError: (error) => {
            enqueueSnackbar(error.response.data.message || 'Αποτυχία', { variant: 'error', autoHideDuration:2000 })
        }
    });
}