import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormContainer, useForm } from 'react-hook-form-mui';
import { Link, useNavigate } from 'react-router-dom';
import Details, { mapByRefValue } from '../../Details/Details/Details';
import useAuth from '../../Services/useAuth';
import { useGetGroupsByUserId } from '../../Services/useGetGroupsByUserId';
import { useGetRequestByAfm } from '../../Services/useGetRequestByAfm';
import { useUpdateUserCommunicationGroups } from '../../Services/useUpdateUserCommunicationGroups';
import AppAccordion from '../../UIComponents/AppAccordion';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import Loader from '../../UIComponents/Loader';
import {
  getSelectedGroupsIds,
  personalData,
  efkaData,
  aadeData,
  erganiData,
  tekaDAta,
  dummyUser,
} from '../../helperFuncs';
import ProtectedRouteViolation from '../AuthPages/ProtectedRouteViolation';
import { useGetActiveGroups } from '../../Services/useGetActiveGroups';
import CommunicationGroups from '../../UIComponents/CommunicationGroups';
import {
  getErganiEmploymentHistory,
  getEfkaEmployment,
  downloadFile,
  isConsent,
  getAadeInfo,
} from '../../Services/httpCalls';
import { useGetErganiHistory } from '../../Services/useGetErganiHistory';
import ErganiDetails from '../../Details/Details/ErganiDetails';
import EfkaDetails from '../../Details/Details/EfkaDetails';
import AADEdetails from '../../Details/Details/AADEdetails';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { enqueueSnackbar } from 'notistack';

const RegistryReview = () => {
  const { getUser } = useAuth();
  const { data: activeGroups } = useGetActiveGroups();
  //const { data: ergani } = useGetErganiHistory();
  const {
    data: request,
    isLoading: isRequestLoading,
    refetch,
    error,
  } = useGetRequestByAfm(getUser()?.afm);
  const { data: groups, refetch: refetchGroups } = useGetGroupsByUserId(
    getUser()?.sub
  );
  const [isGroupsOpen, setIsGroupsOpen] = useState(false);
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const [erganiHistory, setErganiHistory] = useState([]);
  const [efkaEmployment, setEfkaEmployment] = useState([]);
  const [isEfkaEmploymentExist, setIsEfkaEmploymentExist] = useState(false);
  const [isCertified, setIsCertified] = useState(false);
  const [aadeInfo, setAadeInfo] = useState([]);

  const getErganiHistory = async () => {
    try {
      const ergani = await getErganiEmploymentHistory();
      const efka = await getEfkaEmployment();

      setErganiHistory(ergani?.employment);
      setIsEfkaEmploymentExist(efka?.is_employment_exist);
      setEfkaEmployment(efka?.employment?.employers);
    } catch (err) {
      setErganiHistory([]);
      setIsEfkaEmploymentExist(false);
      setEfkaEmployment([]);
    }
  };

  const getAadeData = async () => {
    try {
      const res = await getAadeInfo();
      setAadeInfo(res?.info);
    } catch (err) {
      setAadeInfo([]);
    }
  };

  const fileDownload = async (request_id, profession_id, file_name) => {
    try {
      await downloadFile(request_id, profession_id, file_name);
    } catch (err) {
      enqueueSnackbar('Παρουσιάστηκε σφάλμα στο κατέβασμα του αρχείου', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  };

  const checkCertification = async () => {
    const isNotCertifiedUser = getUser()?.is_certified === 0;
    if (isNotCertifiedUser && request?.data.requests.length > 0) {
      const certification = await isConsent(true);
      if (certification && certification.data?.is_user_certified) {
        setIsCertified(certification.data?.is_user_certified);
      }
    }
  };

  useEffect(() => {
    getErganiHistory();
    checkCertification();
    getAadeData();

    return () => {
      setErganiHistory([]);
      setEfkaEmployment([]);
    };
  }, []);

  useEffect(() => {
    // Delay the appearance of the Link by 4 seconds
    const timer = setTimeout(() => {
      setIsLinkVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const mappeRegistrationData = request?.data?.requests?.[0]
    ? [
        {
          name: 'Ημερομηνία εγγραφής',
          refValue: 'creation_date',
          id: 0,
          value: request.data.requests[0].creation_date,
        },
        {
          name: 'Ημερομηνία τελευταίας μεταβολής',
          refValue: 'last_updated',
          id: 1,
          value: request.data.requests[0].last_updated,
        },
      ]
    : [];

  const mappedPersonalData =
    request?.data?.requests?.[0]?.user &&
    mapByRefValue(
      {
        ...request.data.requests[0].user,
        amka: request.data.requests[0].amka,
      },
      personalData
    );
  const mappedCommunicationData = request?.data?.requests?.[0]
    ? [
        {
          name: 'Εmail',
          refValue: 'email',
          id: 0,
          value: request?.data?.requests[0]?.user.email,
        },
        {
          name: 'Κινητό τηλέφωνο',
          refValue: 'phone_number',
          id: 1,
          value: request?.data?.requests[0]?.phone_number,
        },
      ]
    : [];

  const mappedProfessionalData = request?.data?.requests?.[0]
    ? [
        {
          name: 'Χωροταξική δραστηριοποίηση',
          refValue: 'spatialActivity',
          id: 0,
          value:
            request.data.requests[0].municipalities
              ?.map((item) => item.municipality)
              .join(', ') || '',
        },
        ...(request.data.requests[0].professions?.flatMap((item, index) => [
          {
            name: 'Ειδικότητα/ Επάγγελμα',
            refValue: 'job',
            id: index + 2,
            value: item?.profession || '',
          },
          {
            name: 'Τεκμηρίωση επαγγέλματος/ειδικότητας',
            refValue: 'proof_description',
            id: index + 1,
            value: item.proof_description || '',
          },
          {
            name: 'Τίτλος σπουδών',
            refValue: 'profession_proof',
            id: index + 3,
            value:
              item.proof === 4 ? (
                isLinkVisible ? (
                  (
                    <>
                      <Typography
                        component='a'
                        sx={{
                          cursor: 'pointer',
                          color: 'primary.main',
                          fontWeight: 'bold',
                          textDecoration: 'none',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={() =>
                          fileDownload(
                            request.data.requests[0]?.id,
                            item?.profession_id,
                            item?.file?.name
                          )
                        }
                      >
                        {item?.file?.name}
                      </Typography>
                    </>
                  ) || ''
                ) : (
                  <Box sx={{ height: '50px' }}>
                    <Loader />
                  </Box>
                )
              ) : (
                'Δεν απαιτείται'
              ),
          },
        ]) || []),
      ]
    : [];

  const communicationGroupData = groups?.data?.communication_groups.map(
    (item) => item.category_id
  );
  const navigate = useNavigate();
  const formContext = useForm({});
  const [formData, setFormData] = useState();
  const { mutateAsync: updateCommunicationGroups } =
    useUpdateUserCommunicationGroups({ categories: formData });

  const initializeSelectedGroups = () => {
    const allGroups = activeGroups?.data?.categories;
    if (communicationGroupData && communicationGroupData.length > 0) {
      let initialValues = {};
      allGroups.forEach((category) => {
        initialValues[category.id.toString()] = communicationGroupData.includes(
          category.id
        );
      });

      formContext.reset(initialValues);
    }
  };

  useEffect(() => {
    initializeSelectedGroups();
  }, [isGroupsOpen]);

  const handleUpdateGroups = async (data) => {
    const communicationGroups = getSelectedGroupsIds(data);
    setFormData(communicationGroups);
    await updateCommunicationGroups({ categories: communicationGroups });
    await refetch();
    await refetchGroups();
    setIsGroupsOpen(false); // Close the groups form after update
  };
  let registryReviewContent;

  switch (true) {
    case isRequestLoading:
      registryReviewContent = <Loader />;
      break;
    case error:
      registryReviewContent = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Typography>
            Κατι πήγε στραβά, παρακαλώ προσπαθήστε να συνδεθείτε ξανά
          </Typography>
        </Box>
      );
      break;
    case (!request?.data || request?.data.requests.length === 0) &&
      !isRequestLoading:
      registryReviewContent = <Loader />;
      break;

    case request?.data.requests[0] &&
      getUser()?.sub !== request?.data.requests[0]?.user.id:
      registryReviewContent = <ProtectedRouteViolation />;
      break;

    default:
      registryReviewContent = (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 3,
              mb: 10,
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='h1'>Στοιχεία μητρώου</Typography>
            <Box>
              <GovButton
                ariaLabel={'Αίτηση Μεταβολής Στοιχείων'}
                onClick={() =>
                  navigate(`/request/${request?.data.requests[0]?.id}`)
                }
                variant={'contained'}
                background={'#006736'}
                hoverBackground={'#154a2b'}
                borderColor={'#006736'}
                type={'button'}
              >
                Αίτηση Μεταβολής Στοιχείων
              </GovButton>
            </Box>
          </Box>

          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs={6} sx={{ my: 3 }}>
              <Typography variant='h3'>Πιστοποιημένος χρήστης</Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 3 }}>
              {request?.data.requests[0]?.user?.is_certified === 1 ||
              isCertified ? (
                <Typography>Ναι</Typography>
              ) : (
                <Link
                  style={{ textDecoration: 'none', color: 'blue' }}
                  to='/registration-form'
                >
                  Όχι
                </Link>
              )}
            </Grid>
          </Grid>

          <Typography variant='h3' sx={{ my: 3 }}>
            Προσωπικά στοιχεία
          </Typography>
          <Details id={'personalData'} rows={mappedPersonalData} />

          <Typography variant='h3' sx={{ my: 3 }}>
            Στοιχεία επικοινωνίας
          </Typography>
          <Details rows={mappedCommunicationData} />

          <Typography variant='h3' sx={{ my: 3 }}>
            Στοιχεία εγγραφής
          </Typography>
          <Details rows={mappeRegistrationData} />

          <Box sx={{ mb: 3 }}>
            <Typography variant='h3' sx={{ my: 3 }}>
              Στοιχεία Δραστηριοποίησης
            </Typography>
            <Details rows={mappedProfessionalData} />
          </Box>
          <AppAccordion
            id='ergani'
            title='Στοιχεία Εργάνη'
            details={<ErganiDetails erganiHistory={erganiHistory} />}
          />
          <AppAccordion
            id='efka'
            title='Στοιχεία ΕΦΚΑ'
            details={
              <EfkaDetails
                efkaEmployment={efkaEmployment}
                isEfkaEmploymentExist={isEfkaEmploymentExist}
              />
            }
          />
          <AppAccordion
            id='aade'
            title='Στοιχεία ΑΑΔΕ'
            details={<AADEdetails aadeInfo={aadeInfo} />}
          />

          <Typography
            variant='h3'
            sx={{ fontWeight: 'bold', boxShadow: 'none', padding: 0 }}
          >
            {groups?.data.communication_groups?.length > 0 ? (
              <>
                Ανήκετε στις Ομάδες Επικοινωνίας:
                {groups?.data?.communication_groups.map((item, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'inline', fontWeight: 'regular' }}
                  >
                    {item.category}
                    {index < groups?.data?.communication_groups.length - 1 &&
                      ', '}
                  </Box>
                ))}
              </>
            ) : (
              'Δεν ανήκετε σε κάποια ομάδα επικοινωνίας'
            )}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 3 }}>
            <RoundedButton
              onClick={() => setIsGroupsOpen(true)}
              color={'link'}
              variant='contained'
              arial-label='Αλλαγη ομαδων επικοινωνιας'
            >
              Αλλαγή Ομάδων Επικοινωνίας
            </RoundedButton>
          </Box>
          {isGroupsOpen && (
            <FormContainer
              formContext={formContext}
              onSuccess={handleUpdateGroups}
            >
              <CommunicationGroups />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 3 }}>
                <GovButton
                  ariaLabel={'Υποβολή'}
                  variant={'contained'}
                  background={'#006736'}
                  hoverBackground={'#154a2b'}
                  borderColor={'#006736'}
                  type={'submit'}
                >
                  Υποβολή ομάδων επικοινωνίας
                </GovButton>
              </Box>
            </FormContainer>
          )}
        </Box>
      );
      break;
  }

  return <>{registryReviewContent}</>;
};

export default RegistryReview;
