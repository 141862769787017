import { Box } from "@mui/material";
import SuccessCard from "../../UIComponents/SuccessCard";
import { useLocation } from "react-router-dom";

const RegistrationSuccess = () => {
  const location = useLocation();

  return (
    <Box sx={{ my: 3, display: "flex", alignSelf: "flex-start" }}>
      <SuccessCard
        sx={{
          height: "100px",
          backgroundColor: "success.main",
          color: "white.main",
        }}
        title={
          location.pathname.includes("new")
            ? "Η αίτηση εγγραφής καταχωρήθηκε επιτυχώς"
            : "Η μεταβολή αίτησης καταχωρήθηκε επιτυχώς"
        }
      ></SuccessCard>
    </Box>
  );
};
export default RegistrationSuccess;
