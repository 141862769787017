import { createTheme } from "@mui/material/styles";
import { elGR } from "@mui/material/locale";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const LOADER_SIZE = 20;

export default createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    typography: {
      h1: {
        fontSize: "2rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "2.5rem",
        },
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "2rem",
        },
      },
      h3: {
        fontSize: "1.125rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "1.3rem",
        },
      },
      h4: {
        fontSize: "1rem",
        fontWeight: "700",
        "@media (min-width:600px)": {
          fontSize: "1.1rem",
        },
      },
      h5: {
        fontSize: "0.81rem",
        fontWeight: "700",
      },
      h6: {
        fontSize: "0.81rem",
        fontWeight: "700",
      },
      body1: {
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: "1.43",
      },
    },
    components: {
      MuiFormControl: {
        defaultProps: {
          style: {
            width: "100%",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          inputProps: {
            style: {
              width: "100%",
              padding: "0.5rem 1.25rem",
              border: "1px solid",
              color: "black",
            },
          },
          InputProps: {
            style: {
              borderRadius: 0,
              color: "black",
            },
            sx: {
              "& .MuiInputBase-input.Mui-disabled": {
                color: "black", // Set text color to black when disabled
              },
              "& fieldset": {
                border: "1px solid", // Default border
              },
              "&.Mui-disabled": {
                "& fieldset": {
                  borderColor: "black", // Set border color to black when disabled
                },
              },
            },
          },
        },
      },
      

      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: "400",
            textTransform: "none",
            padding: "0.5rem 1.25rem",
            borderRadius: 0,
            borderBottom: "1px solid black",
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "black",
          },
        },
      },
      MuiOutlinedInput: {
        style: {
          width: "100%",
        },
        styleOverrides: {
          input: {
            padding: "8px 5px",
            border: "1px solid black",
            borderRadius: 0,
            "&:focus": {
              border: "1px solid black",
              borderRadius: 0,
            },
          },
        },
      },
      MuiTextareaAutosize: {
        styleOverrides: {
          root: {
            "&.MuiOutlinedInput-root": {
              padding: "0 !important",
            },
            "&.MuiOutlinedInput-root::before, &.MuiOutlinedInput-root::after": {
              padding: "0 !important",
            },
          },
        },
      },
      MuiMultiSelect: {
        sx: {
          "& .MuiSvgIcon-root": {
            color: "black",
          },
        },
          InputProps: {
            style: {
              borderRadius: 0,
              color: "black",
            },
            sx: {
              "& .MuiInputBase-input.Mui-disabled": {
                color: "black", // Set text color to black when disabled
              },
              "& fieldset": {
                border: "1px solid", // Default border
              },
              "&.Mui-disabled": {
                "& fieldset": {
                  borderColor: "black", // Set border color to black when disabled
                },
              },
            },
          },
        defaultProps: {
          IconComponent: ExpandMoreIcon,
          sx: {
            "& fieldset": {
              padding: 0,
              color: "black",

            },
            "& .MuiSvgIcon-root": {
              color: "black",
            },
          },
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          },
        },
      },
      
      
      
      
      
      MuiSelect: {
        defaultProps: {
          sx: {
            "& .MuiSelect-nativeInput": {
              position: "absolute",
              opacity: 0,
              pointerEvents: "none",
              height: 0,
              width: 0,
              overflow: "hidden",
              visibility: "hidden",
            },
            "&.Mui-disabled": {
              color: "#828086", // Text color when disabled (gray)
              "& .MuiSvgIcon-root": {
                color: "#828086", // Icon color when disabled (gray)
              },
            },
            "& .MuiSvgIcon-root": {
              color: "rgba(0, 0, 0, 0.87)", // Ensure visible select icon has good contrast
            },
            // Add further styling for select
          },
          
        },
      },
      
      
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            '&[aria-disabled="true"]': {
              color: "black",
            },
          },
        },
      },
    },
    palette: {
      white: {
        main: "#FFFFFF",
      },
      black: {
        main: "#000000",
      },
      primary: {
        main: "#003375",
        secondary: "#00b0f0",
        hover: "#0e3d8b",
        dark:'#1b2f51'
      },
      secondary: {
        main: "#00b0f0",
      },
      success: {
        main: "#006736",
        light: "#298060",
        hover: "#154a2b",
      },
      successLight: {
        main: "#298060",
        hover: "#154a2b",
      },
      error: {
        main: "#ca2e2e",
        hover: "#880000",
      },
      light: {
        main: "#616161",
      },
      lightBlue: {
        main: "#54a0cd",
      },
      chartPink: {
        main: "rgb(255, 99, 132)",
      },
      disabled: {
        main: "#ccc",
      },
      warning_alert: {
        light: "rgb(255, 244, 229)",
        dark: "rgb(102, 60, 0)",
      },
      gray: {
        main: "#595858",
        dark: "#828086",
        base: "#99989c",
        light: "#cdccce",
        footer: "#ecebec",
        hover: "#6d6a71",
        lighter: "#f2f2f2",
      },
      action: {
        disabledOpacity: 1,
      },
      link: {
        main: "#0054ba",
        secondary: "#0065b3",
        hover: "#0e3d8b",
      },
      border: {
        main: "#bbb",
        dark: "#797979",
      },
    },
  },
  elGR
);
