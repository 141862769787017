import React from 'react';
import { TreeSelect } from 'antd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './customStyles.css';
import { InputLabel } from '@mui/material';

const disableParentNodes = (nodes) => {
  return nodes.map((node) => {
    if (node.children && node.children.length > 0) {
      return {
        ...node,
        disabled: true,
        children: disableParentNodes(node.children),
        title: <span style={{ color: 'black' }}>{node.label}</span>,
      };
    }
    return node;
  });
};

const MultiTreeSelect = ({ data, onChange, value, error, id }) => {
  const treeData = disableParentNodes(data);

  return (
    <div className='custom-tree-select-wrapper'>
      {/* <label htmlFor={id}/> */}
      <TreeSelect
        id={id}
        showSearch
        style={{
          width: '100%',
          borderRadius: '0px',
          border: error ? '1px solid red' : '1px solid black',
          minWidth: '400px',
        }}
        className='custom-tree-select'
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
          border: 'none',
        }}
        allowClear
        multiple
        size='large'
        treeCheckable
        treeCheckStrictly
        onChange={onChange}
        treeData={treeData}
      />
      <KeyboardArrowDownIcon
        className='custom-arrow'
        style={{ fontSize: '1.5rem', color: '#757575' }}
      />
    </div>
  );
};

export default MultiTreeSelect;
