import { useQuery } from '@tanstack/react-query';
import { getActiveGroups } from './httpCalls';
import { enqueueSnackbar } from 'notistack';

export function useGetActiveGroups() {
  return useQuery({
    queryFn: () => getActiveGroups(),
    queryKey: [`get_active_groups`],
    onSuccess: () => {
      enqueueSnackbar('Επιτυχία!', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error?.response?.data?.message || 'Κάτι πήγε στραβά', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
}
