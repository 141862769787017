import { getOtp } from "./httpCalls";
import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";

export function useNewOtp() {
    return useMutation({
        mutationKey: [`new_otp`],
        mutationFn: async (body) => {
            return await getOtp()
        },
        onSuccess: (response) => {
            enqueueSnackbar('Εκδόθηκε νέος κωδικός', { variant: 'success' , autoHideDuration:2000})
        },
        onError: (error) => {
            enqueueSnackbar('Αποτυχία', { variant: 'error' , autoHideDuration:2000});
        }
    });
}
