import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getOldRequest } from '../../Services/httpCalls';
import useAuth from '../../Services/useAuth';
import Loader from '../../UIComponents/Loader';
import { CustomPagination } from '../../UIComponents/CustomPagination';

const RequestOldHistory = () => {
  const { getUser } = useAuth();
  const [oldRequests, setOldRequests] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = useMemo(() => {
    return Math.ceil(oldRequests?.data?.old_requests.length / LIMIT);
  }, [oldRequests]);

  useEffect(() => {
    const fetchOldRequests = async () => {
      setIsLoading(true);
      try {
        const response = await getOldRequest(getUser()?.afm);

        // Set old requests
        setOldRequests(response.data.old_requests);
      } catch (error) {
        console.error('Failed to fetch old requests', error);
      } finally {
        setIsLoading(false); // This will now run regardless of success or failure
      }
    };

    if (getUser()?.afm) {
      // Ensure AFM is defined
      fetchOldRequests();
    } else {
      setIsLoading(false); // Avoid loading state if getUser().afm is not set
    }
  }, [getUser()?.afm]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 3,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h1'>Ιστορικό αιτήσεων απογραφής</Typography>
      </Box>

      <Typography sx={{ my: 4 }}>
        Το ιστορικό αιτήσεων απογραφής περιέχει το ιστορικό των αιτήσεων που
        έχετε πραγματοποιήσει στο ΠΣ Εργάνη
      </Typography>
      {!isLoading && !oldRequests?.length > 0 && (
        <Typography sx={{ my: 4 }}>
          Δεν έχετε πραγματοποιήσει αίτηση στο ΠΣ Εργάνη
        </Typography>
      )}

      {oldRequests && oldRequests?.length > 0 && (
        <Typography sx={{ my: 4 }}>
          {`Εμφανίζονται `}
          <strong>{oldRequests.length}</strong>
          {` αποτελέσματα`}
        </Typography>
      )}

      {isLoading ? (
        <Box aria-busy={true} aria-label='Loading'>
          <Loader />
        </Box>
      ) : (
        <Box>
          {oldRequests && (
            <DataGrid
              suppressColumnVirtualisation={true}
              suppressRowVirtualisation={true}
              role='grid'
              id='grid'
              aria-colcount='1'
              aria-rowcount={oldRequests?.length}
              aria-multiselectable='false'
              aria-label='Πίνακας Ιστορικού'
              initialState={{
                pagination: { paginationModel: { pageSize: LIMIT, page: 0 } },
              }}
              getRowHeight={() => 'auto'}
              pageSizeOptions={[5, 10, 25]}
              pagination
              pageSize={5}
              slots={{ pagination: CustomPagination }}
              slotProps={{
                pagination: {
                  totalCount: oldRequests?.length,
                  setOffset: setOffset,
                  limit: LIMIT,
                  pageCount: pageCount,
                  setCurrentPage: setCurrentPage,
                  currentPage: currentPage,
                },
              }}
              disableRowSelectionOnClick
              columnHeaderHeight={1}
              sx={{
                '&, [class^=MuiDataGrid]': {
                  border: 'none',
                  overflow: 'hidden',
                  justifyContent: 'flex-start',
                },
              }}
              rows={oldRequests}
              columns={[
                {
                  field: 'request',
                  flex: 1,
                  headerName: '',
                  renderCell: (params) => (
                    <Box
                      sx={{
                        borderBottom: '1px solid',
                        borderColor: 'gray.light',
                        width: '100%',
                      }}
                    >
                      <Typography
                        component={Link}
                        to={`/old-request-review/${params.id}`}
                        sx={{
                          color: 'link.main',
                          px: 0.5,
                          textDecoration: 'underline',
                        }}
                      >
                        {
                          'Υπεύθυνη Δήλωση Απογραφής στο Μητρώο Καλλιτεχνών, Δημιουργών και Επαγγελματιών της Τέχνης και του Πολιτισμού'
                        }
                      </Typography>
                      <Typography sx={{ color: 'gray.main' }}>
                        {params.row.submit_date}
                      </Typography>
                    </Box>
                  ),
                },
              ]}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default RequestOldHistory;
