import * as yup from 'yup';

const formValidation = () =>
  yup.object().shape({
    amka: yup
      .string()
      .required('Το πεδίο είναι υποχρεωτικό')
      .matches(/^[0-9]+$/, 'Επιτρέπονται μόνο αριθμοί')
      .min(11, 'Πληκτρολογήστε έναν αριθμό με 11 ψηφία')
      .max(11, 'Πληκτρολογήστε έναν αριθμό με 11 ψηφία'),
    phone: yup
      .string()
      .required('Το πεδίο είναι υποχρεωτικό')
      .matches(/^[0-9]+$/, 'Επιτρέπονται μόνο αριθμοί')
      .min(10, 'Πληκτρολογήστε έναν αριθμό με 10 ψηφία')
      .max(10, 'Πληκτρολογήστε έναν αριθμό με 10 ψηφία'),
    profesionalData: yup.array().of(
      yup.object().shape({
        profession_proof_id: yup
          .string()
          .required('Επιλέξτε τον τύπο τεκμηρίωσης επαγγέλματος'),
        profession_id: yup.string().required('Επιλέξτε επάγγελμα / ειδικότητα'),
      })
    ),
  });

export default formValidation;
