import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HttpService from '../../Services/environment';
import useAuth from '../../Services/useAuth';
import { useGetRequestByAfm } from '../../Services/useGetRequestByAfm';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import Loader from '../../UIComponents/Loader';
import ProtectedRouteViolation from '../AuthPages/ProtectedRouteViolation';
import { CustomPagination } from '../../UIComponents/CustomPagination';

const RequestHistory = () => {
  const navigate = useNavigate();
  const { getUser } = useAuth();
  const { data: requests, isLoading: isRequestsLoading } = useGetRequestByAfm(
    getUser()?.afm
  );
  const [history, setHistory] = useState({ data: { history: [] } });
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = useMemo(() => {
    return Math.ceil(history?.data?.history.length / LIMIT);
  }, [history]);

  useEffect(() => {
    const getHistory = async () => {
      if (requests?.data?.requests?.[0]?.id) {
        const resp = await HttpService.getAxiosClient().get(
          `requests/${requests?.data?.requests[0].id}/history`
        );
        setHistory(resp);
      }
    };

    if (requests?.data?.requests?.length > 0) {
      getHistory();
    }
  }, [requests]);

  let registryHistoryContent;

  switch (true) {
    case isRequestsLoading:
      registryHistoryContent = <Loader />;
      break;

    case !requests?.data || requests?.data.requests.length === 0:
      registryHistoryContent = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Typography>
            Δεν έχετε πραγματοποιήσει κάποια εγγραφή στο μητρώο καλλιτεχνών
          </Typography>
          <RoundedButton
            onClick={() => navigate(`/request`)}
            variant={'contained'}
            color={'successLight'}
            arial-label='Αίτηση Εγγραφής'
          >
            Αίτηση Εγγραφής
          </RoundedButton>
        </Box>
      );
      break;

    case requests?.data?.requests?.[0] &&
      getUser()?.sub !== requests?.data?.requests?.[0]?.user?.id:
      registryHistoryContent = <ProtectedRouteViolation />;
      break;

    default:
      const initialRequestData = requests?.data?.requests?.[0]
        ? {
            id: requests.data.requests[0].id,
            request_id: requests.data.requests[0].id,
            el_date: requests.data.requests[0].creation_date,
          }
        : null;

      registryHistoryContent = (
        <Box>
          <Typography sx={{ my: 4, fontSize: '30px' }}>Η θυρίδα μου</Typography>
          <Typography variant='h1'>Ιστορικό</Typography>
          <Typography sx={{ my: 4 }}>
            Η θυρίδα σας περιέχει το ιστορικό των αλλαγών που έχετε
            πραγματοποιήσει
          </Typography>
          <Typography sx={{ my: 4 }}>
            {`Εμφανίζονται `}
            <strong>
              {history?.data?.history.length + (initialRequestData ? 1 : 0)}
            </strong>
            {` αποτελέσματα`}
          </Typography>
          {isRequestsLoading ? (
            <Box aria-busy={true} aria-label='Loading'>
              <Loader />
            </Box>
          ) : (
            <Box>
              {history?.data?.history && (
                <DataGrid
                  suppressColumnVirtualisation={true}
                  suppressRowVirtualisation={true}
                  role='grid'
                  id='grid'
                  aria-colcount='1'
                  aria-rowcount={history?.data?.history.length}
                  aria-multiselectable='false'
                  aria-label='Πίνακας Ιστορικού'
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: LIMIT, page: 0 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  pagination
                  pageSize={5}
                  slots={{ pagination: CustomPagination }}
                  slotProps={{
                    pagination: {
                      totalCount: history?.data?.history.length,
                      setOffset: setOffset,
                      limit: LIMIT,
                      pageCount: pageCount,
                      setCurrentPage: setCurrentPage,
                      currentPage: currentPage,
                    },
                  }}
                  disableRowSelectionOnClick
                  columnHeaderHeight={1}
                  sx={{
                    '&, [class^=MuiDataGrid]': {
                      border: 'none',
                      overflow: 'hidden',
                      justifyContent: 'flex-start',
                    },
                  }}
                  rows={[
                    ...(history?.data?.history || []),
                    ...(initialRequestData ? [initialRequestData] : []),
                  ]}
                  columns={[
                    {
                      field: 'request',
                      flex: 1,
                      headerName: '',
                      renderCell: (params) => (
                        <Box
                          sx={{
                            borderBottom: '1px solid',
                            borderColor: 'gray.light',
                            width: '100%',
                          }}
                        >
                          <Typography
                            sx={{
                              color: 'black.main',
                              px: 0.5,
                            }}
                          >
                            {params.row.changes
                              ? 'Μεταβολη αιτησης στο μητρώο απασχολούμενων του πολιτιστικού και δημιουργικού τομέα'
                              : 'Αίτηση - Εγγραφή στο μητρώο απασχολούμενων του πολιτιστικού και δημιουργικού τομέα'}
                          </Typography>
                          <Typography sx={{ color: 'gray.main' }}>
                            {`${params.row.el_date} ${
                              params.row.changes || ''
                            }`}
                          </Typography>
                        </Box>
                      ),
                    },
                  ]}
                />
              )}
            </Box>
          )}
        </Box>
      );
      break;
  }

  return <>{registryHistoryContent}</>;
};

export default RequestHistory;
