import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useForm, FormProvider } from 'react-hook-form';
import GovButton from '../Buttons/GovButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { formAdminValidation } from './FormAdmin/adminValidation';
import AdminForm from './FormAdmin/adminForm';

export default function EditAdminModal(props) {
  const {
    openEditAdminModal,
    handleCloseEditAdmin,
    submitEditAdmin,
    editDefaultValues,
    editUserId,
  } = props;

  const methods = useForm({
    resolver: yupResolver(formAdminValidation),
  });

  // Handle form submission
  const onSubmit = methods.handleSubmit((data, event) => {
    event.preventDefault();
    submitEditAdmin(data);
    methods.reset();
  });

  useEffect(() => {
    methods.reset(editDefaultValues);
  }, [editDefaultValues]);

  return (
    <FormProvider {...methods}>
      <Dialog
        open={openEditAdminModal}
        onClose={handleCloseEditAdmin}
        fullWidth
      >
        <form onSubmit={onSubmit}>
          <DialogContent>
            <AdminForm editUserId={editUserId} />
          </DialogContent>
          <DialogActions sx={{ margin: 2 }}>
            <Box sx={{ marginRight: 2 }}>
              <GovButton
                ariaLabel={'cancel-edit-addition'}
                type={'button'}
                background={'white'}
                hoverBackground={'white'}
                onClick={handleCloseEditAdmin}
                color={'#003476'}
                borderColor={'#003476'}
                variant={'outlined'}
              >
                Ακύρωση
              </GovButton>
            </Box>
            <GovButton
              ariaLabel={'submit-edit-addition'}
              type={'submit'}
              background={'#003476'}
              borderColor={'#003476'}
              hoverBackground={'#02234e'}
              color={'white'}
              variant={'contained'}
            >
              Υποβολή
            </GovButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}
