import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react'; // Import useEffect
import { FormContainer, SelectElement, useForm } from 'react-hook-form-mui';
import * as yup from 'yup';
import { useIsConsent } from './useIsConsent';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import GovButton from '../../UIComponents/Buttons/GovButton';

const AADEConfirm = () => {
  const confirmValidation = yup.object().shape({
    confirm: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
  });
  const formContext = useForm({
    resolver: yupResolver(confirmValidation),
  });

  const [confirmation, setConfirmation] = useState(null);
  const { mutateAsync: setIsConsent } = useIsConsent(confirmation); // Initial value of confirmation

  useEffect(() => {
    if (confirmation !== null) {
      setIsConsent(confirmation);
    }
  }, [confirmation, setIsConsent]);

  const mappedAnswers = [
    {
      id: 'no',
      label: 'Όχι',
    },
    {
      id: 'yes',
      label: 'Ναι',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 3,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant='h1'>
        Ρητή συγκατάθεση σχετικά με την αίτηση σας
      </Typography>
      <Typography>
        Η ρητή συγκατάθεσή σας είναι απαραίτητη για την ταυτοποίηση σαν
        πιστοποιημένος χρήστης μέσω ΚΑΔ.
      </Typography>
      <Typography variant='h3'>
        Συναινώ να χρησιμοποιηθούν τα στοιχεία μου από ΑΑΔΕ για εύρεση των ΚΑΔ
        (σε περίπτωση μη συναίνεσης δεν θα ελεγχθούν οι ΚΑΔ στο σύστημα της ΑΑΔΕ
        και υπάρχει περίπτωση να εγγραφείτε σαν μη πιστοποιημένος χρήστης)
      </Typography>
      <FormContainer
        formContext={formContext}
        onSuccess={(data) => {
          data.confirm === 'no'
            ? setConfirmation(false)
            : setConfirmation(true);
        }}
      >
        <SelectElement
          name={'confirm'}
          options={mappedAnswers}
          inputProps={{
            'aria-label': 'Συγκαταθεση για χρηση στοιχείων από ΑΑΔΕ ',
          }}
        />

        <Box sx={{ my: 4, mb: 10 }}>
          <GovButton
            ariaLabel={'Συνέχεια'}
            type={'submit'}
            variant='contained'
            background={'#006736'}
            hoverBackground={'#154a2b'}
            borderColor={'#006736'}
          >
            Συνέχεια
          </GovButton>
        </Box>
      </FormContainer>
    </Box>
  );
};

export default AADEConfirm;
