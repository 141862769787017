import * as yup from 'yup';

export const questionValidation = yup.object().shape({
  category_id: yup
    .array()
    .min(1, 'Εισαγάγετε τουλάχιστον μία κατηγορία για την ερώτηση'),
  subject: yup.string().required('Εισαγάγετε το θέμα της ερώτησης'),
  description: yup.string().required('Εισαγάγετε το κείμενο της ερώτησης'),
});

export const answerValidation = yup.object().shape({
  answer: yup.string().required('Εισαγάγετε το απάντηση στην ερώτησης'),
});
