import React from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import Theme from './Theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import HttpService from './Services/environment';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
HttpService.configure()
root.render(
  <QueryClientProvider client={queryClient}>

    <ThemeProvider theme={Theme}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </QueryClientProvider>
);

reportWebVitals();
