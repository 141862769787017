import * as yup from 'yup';

export const formAdminValidation = yup.object().shape({
  first_name: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
  last_name: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
  afm: yup
    .string()
    .required('Το πεδίο είναι υποχρεωτικό')
    .min(9, 'Το ΑΦΜ είναι 9ψήφιο')
    .max(9, 'Το ΑΦΜ είναι 9ψήφιο'),
  fathers_name: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
  mothers_name: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
  email: yup
    .string()
    .required('Το πεδίο είναι υποχρεωτικό')
    .email('Το πεδίο πρέπει να είναι email'),
});
