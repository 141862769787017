import { Box, Typography } from "@mui/material";

const InactiveUserPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 3,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant="h1">Σφάλμα κατά τη σύνδεσή σας.</Typography>
      <Typography variant="h3">
        Ο λογαριασμός σας στην εφαρμογή φαίνεται να είναι απενεργοποιημένος.
      </Typography>
      <Typography>
        Παρακαλώ επικοινωνήστε με την αρμόδια αρχή για την ενεργοποίηση του
        λογαριασμού σας.
      </Typography>
      <Box></Box>
    </Box>
  );
};
export default InactiveUserPage;
