import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Button, InputLabel, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import {
  FormContainer,
  SelectElement,
  useFieldArray,
  useForm,
} from 'react-hook-form-mui';
import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '../../Services/useAuth';
import { useGetActiveGroups } from '../../Services/useGetActiveGroups';
import { useGetDegrees } from '../../Services/useGetDegrees';
import { useGetGroupsByUserId } from '../../Services/useGetGroupsByUserId';
import { useGetJobs } from '../../Services/useGetJobs';
import { useGetMunicipalities } from '../../Services/useGetMunicipalities';
import { useGetRequestByAfm } from '../../Services/useGetRequestByAfm';
import { useGetUser } from '../../Services/useGetUser';
import { usePostNewRequest } from '../../Services/usePostNewRequest';
import { useUpdateRequest } from '../../Services/useUpdateRequest';
import { useUpdateUserCommunicationGroups } from '../../Services/useUpdateUserCommunicationGroups';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import GovWrapper from '../../UIComponents/GovWrapper';
import Loader from '../../UIComponents/Loader';
import SaveModal from '../../UIComponents/Modals/SaveModal';
import CommunicationGroups from '../../UIComponents/CommunicationGroups';
import SingleFileUploader from '../../UIComponents/SingleFileUploader';
import SuccessSnackBar from '../../UIComponents/SuccessSnackBar';
import {
  buildTreeData,
  getSelectedGroupsIds,
  splitAtLastBackslash,
  transformToFormData,
  base64ToFile,
} from '../../helperFuncs';
import formValidation from './formValidation';
import {
  modReqDefaultValues,
  newReqDefaultValues,
} from './newReqDefaultValues';
import {
  getIsFileUploaded,
  getOldRequest,
  getJobs,
  getUserData,
  isAmkaAvailable,
} from '../../Services/httpCalls';
import PersonalInfoSection from './PersonalInfoSection';
import ContactInfoSection from './ContactInfoSection';
import MunicipalitySelect from './MunicipalitySelect';
import ProtectedRouteViolation from '../AuthPages/ProtectedRouteViolation';
import GovButton from '../../UIComponents/Buttons/GovButton';

const NewRequestForm = () => {
  const { id } = useParams();
  const { getUser } = useAuth();
  const { data: groups } = useGetGroupsByUserId(getUser()?.sub);
  const communicationGroupData = groups?.data.communication_groups.map(
    (item) => item.category_id
  );
  const [fileErrors, setFileErrors] = useState({});
  const [municipalitiesAreEmpty, setMunicipalitiesAreEmpty] = useState(
    !id ? true : false
  );
  const { data: activeGroups } = useGetActiveGroups();
  const [fileNames, setFileNames] = useState();
  const [openSuccessToast, setOpenSuccessToast] = useState(false);
  const [isShownSaveModal, setIsShownSaveModal] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);
  const [formData, setFormData] = useState();
  const { data: userData } = useGetUser(getUser()?.afm);
  const { mutateAsync: updateCommunicationGroups } =
    useUpdateUserCommunicationGroups({ categories: formData });
  const { data: jobs, isLoading: isJobsLoading } = useGetJobs();
  const { data: request, isLoading: isRequestLoading } = useGetRequestByAfm(
    getUser()?.afm
  );

  const [selectedProfIds, setSelectedProfIds] = useState([]);
  const [oldRequestExist, setOldRequestExist] = useState(false);
  const [oldFiles, setOldFiles] = useState([]);

  const { data: degrees, isLoading: isDegreesLoading } = useGetDegrees();
  const { data: municipalities, isLoading: isMunicipalitiesLoading } =
    useGetMunicipalities();
  const [selectedMunicipalities, setSelectedMunicipalities] = useState(
    id ? request?.data.requests[0].municipalities.map((m) => m.id) : []
  );
  const mappedDegrees = degrees?.data?.proofs?.map((item) => ({
    id: +item.id,
    label: item.description,
  }));
  const mappedJobs = jobs?.data?.professions?.map((item) => ({
    id: item.id,
    label: item.description,
  }));
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const formContext = useForm({
    resolver: yupResolver(formValidation()),
  });
  const getWatchedValue = (index) => {
    const watchedValue = formContext.watch(
      `profesionalData[${index}].profession_proof_id`
    );
    return watchedValue;
  };

  const initializeProfessionProof = (registration_type) => {
    let profession_proof_id = '';
    if (registration_type === 0) {
      profession_proof_id = '4';
    } else if (registration_type === 1) {
      profession_proof_id = '5';
    } else if (registration_type === 2) {
      profession_proof_id = '6';
    }

    return profession_proof_id;
  };

  const initializeNewRequestForm = async () => {
    const res = await getOldRequest(getUser()?.afm);
    const userEmail = (await getUserData(getUser()?.afm))?.data?.user?.email;
    const oldRequests = res.data?.old_requests;
    if (oldRequests.length > 0) {
      setOldRequestExist(true);
      const oldAmka = oldRequests[0]?.amka;

      let oldProfessions = [];
      const professions = await getJobs();
      res.data?.old_requests.forEach((req) => {
        const searchProfession = professions?.data?.professions.find(
          (row) => row.code === req.speacialty_code
        );

        const profession_proof_id = initializeProfessionProof(
          req.registration_type
        );

        if (searchProfession) {
          // Check if the code already exists in oldProfessions
          const isDuplicate = oldProfessions.some(
            (prof) => prof.code === searchProfession.code
          );

          if (!isDuplicate) {
            oldProfessions.push({
              ...searchProfession,
              profession_proof_id: profession_proof_id,
            });
          }
        }

        if (req.file_content && req.file_name) {
          const oldFile = base64ToFile(req.file_content, req.file_name);
          setOldFiles((prevState) => [...prevState, oldFile]);
          // setFileNames();
        }
      });

      formContext.reset({
        name: getUser()?.first_name,
        surname: getUser()?.last_name,
        fathersName: getUser()?.fathers_name,
        mothersName: getUser()?.mothers_name,
        afm: getUser()?.afm,
        email: userEmail,
        // amka: oldAmka, // #TODO Να το αρχικοποιεί από το ws data2amka
        // phone: oldPhone,
        profesionalData: oldProfessions.map((profession) => ({
          profession_id: profession.id,
          profession_proof_id: Number(profession.profession_proof_id),
        })),
      });
    } else {
      formContext.reset(
        newReqDefaultValues({ ...getUser(), email: userEmail })
      );
    }
  };

  useEffect(() => {
    if (!id) {
      initializeNewRequestForm();
    }
  }, []);

  const getDefaultDegees = () => {
    const professionsLength = request?.data?.requests[0]?.professions.length;
    let defaultDegrees = {};
    for (let i = 0; i < professionsLength; i++) {
      defaultDegrees = {
        [i]:
          request?.data?.requests[0]?.professions[i]?.proof_description ===
          'Tίτλος Σπουδών',
      };
    }
    return defaultDegrees;
  };
  const [degreeSelectedStates, setDegreeSelectedStates] = useState(
    id ? getDefaultDegees() : {}
  );
  const getSelectedMun = () => {
    return selectedMunicipalities?.map((mun) => {
      if (typeof mun === 'string') {
        return mun;
      } else if (typeof mun === 'object' && mun !== null) {
        return mun.value;
      }
    });
  };

  const handleProfIdChange = (index, selectedValue) => {
    if (selectedProfIds.includes(selectedValue)) {
      enqueueSnackbar('Εχετε ήδη επιλέξει αυτό το επάγγελμα', {
        variant: 'warning',
        autoHideDuration: 4000,
      });
    } else {
      setSelectedProfIds((prevProfIds) => {
        const newProfIds = [...prevProfIds];
        newProfIds[index] = selectedValue;
        return newProfIds;
      });
    }
  };

  const handleSelectChange = (index, selectedValue) => {
    if (fileErrors[selectedProfIds[index]] && selectedValue !== 4) {
      setFileErrors((prevState) => ({
        ...prevState,
        [selectedProfIds[index]]: false,
      }));
    }
    setDegreeSelectedStates((prevStates) => ({
      ...prevStates,
      [index]: selectedValue === 4,
    }));
  };

  useEffect(() => {
    if (!isRequestLoading && request?.data?.requests.length > 0) {
      setSelectedProfIds(
        request?.data?.requests[0]?.professions.map(
          (item) => item.profession_id
        )
      );
      setSelectedMunicipalities(
        request?.data?.requests[0]?.municipalities?.map((m) => m.id) || []
      );
    }
  }, [isRequestLoading, request?.data]);

  useEffect(() => {
    if (id && getUser() && !isRequestLoading) {
      const firstId = activeGroups?.data?.categories[0].id;
      const lastId =
        activeGroups?.data?.categories[
          activeGroups?.data?.categories.length - 1
        ].id;
      const initialComGroupsValues = {};
      for (let i = firstId; i <= lastId; i++) {
        initialComGroupsValues[i] = communicationGroupData?.includes(i);
      }
      formContext.reset(
        modReqDefaultValues({
          ...request?.data?.requests[0],
          initialComGroupsValues,
        })
      );

      request?.data?.requests[0]?.professions?.forEach((activity, index) => {
        append({
          profession_id: activity.profession_id,
          profession_proof_id: +activity.proof,
        });
        setDegreeSelectedStates((prevStates) => ({
          ...prevStates,
          [index]: activity.proof === 4,
        }));
      });
    }
  }, [id, formContext, request?.data, isRequestLoading]);

  const handleOpenSuccessToast = () => {
    setOpenSuccessToast(true);
  };

  const { mutateAsync: postNewRequest, data: newRequestData } =
    usePostNewRequest(
      id
        ? {}
        : transformToFormData(
            {
              amka: formData?.data?.amka,
              phone_number: formData?.data?.phone,
              municipalities: formData?.data.spatialActivity,
              activities: formData?.data?.profesionalData,
              files: formData?.data?.files,
            },
            fileNames,
            files
          )
    );
  const { mutateAsync: updateRequest } = useUpdateRequest(
    {
      amka: formData?.data?.amka,
      phone_number: formData?.data?.phone,
      municipality_ids: formData?.data.spatialActivity,
      activities: formData?.data?.profesionalData.map(
        ({ fileName, ...rest }) => rest
      ),
    },
    id
  );

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessToast(false);
  };

  const handleUpdateRequest = async () => {
    const updatePayload = {
      amka: formData?.data?.amka,
      phone_number: formData?.data?.phone,
      municipality_ids: formData?.data.spatialActivity,
      activities: formData?.data?.profesionalData.map(
        ({ fileName, ...rest }) => rest
      ),
    };
    let successFlag = false;

    try {
      const amkaExist = await isAmkaAvailable({
        amka: updatePayload.amka,
        request_id: id,
      });
      if (amkaExist.is_amka_exist) {
        enqueueSnackbar(
          'To AMKA που δώσατε χρησιμοποιείται από άλλο χρήστη. Βεβαιωθείτε οτι δηλώσατε το δικό σας ΑΜΚΑ',
          {
            variant: 'error',
            autoHideDuration: 6000,
          }
        );
        return;
      }
      await Promise.all([
        updateRequest(updatePayload), // Pass the simplified object directly
        updateCommunicationGroups({ categories: formData.communicationGroups }),
      ]);
      successFlag = true;
      setTimeout(() => {
        if (id && successFlag) {
          handleOpenSuccessToast();
          navigate(`/request-review/${id}/success-update`);
        }
      }, 1000);
    } catch (error) {
      enqueueSnackbar(error.response.data.message || 'Κατι πήγε στραβά!', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  };

  const handleCreateRequest = async () => {
    try {
      const amkaExist = await isAmkaAvailable({
        amka: formData?.data?.amka,
      });
      if (amkaExist.is_amka_exist) {
        enqueueSnackbar(
          'To AMKA που δώσατε χρησιμοποιείται από άλλο χρήστη. Βεβαιωθείτε οτι δηλώσατε το δικό σας ΑΜΚΑ',
          {
            variant: 'error',
            autoHideDuration: 6000,
          }
        );
        return;
      }
      await postNewRequest();
      await updateCommunicationGroups({
        categories: formData.communicationGroups,
      });
    } catch (error) {
      enqueueSnackbar(error.response.data.message || 'Κατι πήγε στραβά!', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  };

  const handleSave = () => {
    setIsShownSaveModal(false);
    id ? handleUpdateRequest() : handleCreateRequest();
  };

  const { fields, append, remove } = useFieldArray({
    control: formContext.control,
    rules: { minLength: 1 },
    name: 'profesionalData',
  });

  const removeSelectedProfId = (profIdToRemove) => {
    setSelectedProfIds((prevIds) => {
      const previousIds = [...prevIds];
      return previousIds.filter((value) => value !== profIdToRemove);
    });
  };

  const removeFileAlert = () => {
    enqueueSnackbar(
      'Θα πρέπει να διαγράψετε το αρχείο για να αφαιρέσετε τη δραστηριότητα',
      {
        variant: 'error',
        autoHideDuration: 4000,
      }
    );
  };

  const clearFileErrors = (profId) => {
    if (fileErrors[profId]) {
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [profId]: false,
      }));
    }
  };

  const updateDegreeSelectedState = (index) => {
    if (degreeSelectedStates[index]) {
      setDegreeSelectedStates((prevStates) => ({
        ...prevStates,
        [index]: false,
      }));
    }
  };

  const handleRemove = async (index) => {
    try {
      const profIdToRemove = selectedProfIds[index];

      if (!id || selectedProfIds.length === 0) {
        removeSelectedProfId(profIdToRemove);
        remove(index);
        return;
      }

      const { data } = await getIsFileUploaded(`${id}/${profIdToRemove}`);

      if (data?.is_file_exist) {
        removeFileAlert();

        return;
      }

      clearFileErrors(profIdToRemove);
      updateDegreeSelectedState(index);

      remove(index);
    } catch (error) {
      console.error('Error checking file existence:', error);
    }
  };
  const data = useMemo(
    () => buildTreeData(municipalities?.data?.municipalities),
    [municipalities]
  );
  const onChange = (newValues) => {
    setSelectedMunicipalities(newValues);
    setMunicipalitiesAreEmpty(!newValues?.length > 0);
    setIsSubmited(false);
    setIsShownSaveModal(false);
  };

  const handleSubmitForm = async () => {
    const isValid = await formContext.trigger();
    if (isValid && isSubmited && !municipalitiesAreEmpty) {
      setIsShownSaveModal(true);
    }
  };

  const OldRequestInfoAlert = () => {
    if (oldRequestExist) {
      return (
        <Alert severity='info'>
          Τα παρακάτω στοιχεία έχουν προσυμπληρωθεί αυτόματα από τις υπεύθυνες
          δηλώσεις που έχετε κάνει στο σύστημα Υπεύθυνων Δηλώσεων Απογραφής στο
          Μητρώο Καλλιτεχνών (ΕΡΓΑΝΗ). Σε κάθε περίπτωση μπορείτε να αλλάξετε τα
          συγκεκριμένα στοιχεία στην παρούσα αίτηση εγγραφής.
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      {isJobsLoading || isDegreesLoading || isMunicipalitiesLoading ? (
        <Loader />
      ) : request?.data?.requests[0] &&
        getUser()?.sub !== request?.data?.requests[0]?.user?.id ? (
        <ProtectedRouteViolation />
      ) : (
        <FormContainer
          formContext={formContext}
          onSuccess={(data) => {
            setIsSubmited(true);
            const communicationGroups = getSelectedGroupsIds(data);
            const updatedProfessionalData = data.profesionalData.map(
              (item, index) => ({
                ...item,
                fileName: item.fileName
                  ? splitAtLastBackslash(item.fileName)
                  : request.data.requests[0]?.professions[index]?.file.name ||
                    'Δεν απαιτείται',
                profession_proof_id: +item.profession_proof_id,
              })
            );

            setFormData({
              data: {
                ...data,
                profesionalData: updatedProfessionalData,
                files: files,
                spatialActivity: id
                  ? getSelectedMun()
                  : selectedMunicipalities.map((mun) => mun.value),
              },
              communicationGroups,
            });

            const hasFileErrors =
              fileErrors &&
              Object.entries(fileErrors).some(
                ([key, error]) => key !== 'undefined' && error === true
              );
            setIsShownSaveModal(!hasFileErrors && !municipalitiesAreEmpty);
          }}
        >
          <Typography sx={{ my: 2 }} variant='h2'>
            {` Αίτηση ${
              id ? 'μεταβολής' : 'εγγραφής'
            } στο μητρώο απασχολούμενων`}
          </Typography>

          <PersonalInfoSection />
          <ContactInfoSection />

          <MunicipalitySelect
            data={data}
            setMunicipalitiesAreEmpty={setMunicipalitiesAreEmpty}
            selectedMunicipalities={selectedMunicipalities}
            onChange={onChange}
            isSubmited={isSubmited}
          />

          <Typography variant='h3' sx={{ my: 4 }}>
            Δηλώστε την ειδικότητα ή το επάγγελμα σας που αφορά τον πολιτιστικο
            και δημιουργικό τομέα
          </Typography>

          <OldRequestInfoAlert />
          {fields?.map((field, index) => {
            return (
              <Box
                key={field.id}
                sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  <Typography variant='h3'>
                    {`Δραστηριότητα #${index + 1}`}
                  </Typography>
                  <RoundedButton
                    aria-label='Αφαίρεση'
                    onClick={() => {
                      fields?.filter((field) => !field.isHidden).length > 1
                        ? handleRemove(index)
                        : enqueueSnackbar(
                            'Θα πρέπει να υπάρχει τουλάχιστον 1 δραστηριότητα',
                            { variant: 'warning', autoHideDuration: 4000 }
                          );
                    }}
                    variant='outlined'
                    color='black'
                  >
                    Αφαίρεση
                  </RoundedButton>
                </Box>
                <GovWrapper
                  id='job'
                  label={'Επάγγελμα / ειδικότητα'}
                  width={'100%'}
                >
                  <SelectElement
                    name={`profesionalData.${index}.profession_id`}
                    onChange={(event) => handleProfIdChange(index, event)}
                    options={mappedJobs}
                    inputProps={{
                      'aria-labelledby': 'job',
                    }}
                  />
                </GovWrapper>
                <GovWrapper
                  id={'profesionalData'}
                  label={'Τεκμηρίωση επαγγέλματος'}
                  width={'100%'}
                >
                  <SelectElement
                    id='profesionalData'
                    name={`profesionalData.${index}.profession_proof_id`}
                    options={mappedDegrees}
                    inputProps={{
                      'aria-labelledby': 'profesionalData',
                    }}
                    onChange={(event) => handleSelectChange(index, event)}
                    defaultValue={getWatchedValue(index)}
                  />
                </GovWrapper>
                {(getWatchedValue(index) === 4 ||
                  field.profession_proof_id === '4') && (
                  <Box display='flex' flexDirection='column' gap='25px'>
                    <InputLabel
                      htmlFor={`profesionalData.${index}.file`}
                      sx={{ fontWeight: 'bold' }}
                    >
                      Τίτλος σπουδών από ημεδαπό ή αλλοδαπό ίδρυμα/σχολή
                      καλλιτεχνικής εκπαίδευσης συναφές με τον πολιτιστικό και
                      δημιουργικό τομέα
                    </InputLabel>
                    <SingleFileUploader
                      id={`profesionalData.${index}.file`}
                      index={index}
                      setFileErrors={setFileErrors}
                      setFiles={setFiles}
                      setFileNames={setFileNames}
                      reqId={request?.data?.requests[0]?.id}
                      profId={
                        request?.data?.requests[0]?.professions[index]
                          ?.profession_id || selectedProfIds[index]
                      }
                      savedFile={
                        request?.data?.requests[0]?.professions[index]?.file ||
                        null
                      }
                      oldFile={oldFiles[index] || null}
                      placeholder='Μεταφόρτωση Αποδεικτικού'
                    />
                  </Box>
                )}
              </Box>
            );
          })}
          <Typography sx={{ my: 2 }}>
            Εφόσον δραστηριοποιείστε σε παραπάνω απο μία ειδικότητες ή
            επαγγέλματα επαναλάβετε για να προσθέσετε
          </Typography>
          <RoundedButton
            aria-label='Προσθήκη δραστηριότητας'
            onClick={() =>
              append({
                profession_id: '',
                profession_proof_id: null,
              })
            }
            variant='outlined'
            color='black'
            sx={{ width: '100%', boxShadow: 3, my: 2 }}
          >
            Προσθήκη δραστηριότητας
          </RoundedButton>
          <CommunicationGroups />
          <Box
            sx={{ display: 'flex', gap: 3, my: 3, justifyContent: 'flex-end' }}
          >
            <GovButton
              ariaLabel={'Οριστική υποβολή'}
              type={'submit'}
              variant='contained'
              background={'#006736'}
              hoverBackground={'#154a2b'}
              borderColor={'#006736'}
              onClick={handleSubmitForm}
            >
              Οριστική υποβολή
            </GovButton>
            {/*  <Button
              aria-label='Οριστική υποβολή'
              sx={{
                bgcolor: 'success.main',
                '&:hover': { bgcolor: 'success.hover' },
              }}
              variant='contained'
              type='submit'
              onClick={handleSubmitForm}
            >
              Οριστική υποβολή
            </Button> */}
          </Box>
          <SuccessSnackBar
            handleClose={handleCloseToast}
            open={openSuccessToast}
            message={'Η αίτησή σας υποβλήθηκε'}
          />
          {isShownSaveModal && (
            <SaveModal
              title='Είστε σίγουρος για την οριστική υποβολή της αίτησης;'
              setIsShownSaveModal={setIsShownSaveModal}
              onSave={handleSave}
            />
          )}
        </FormContainer>
      )}
    </>
  );
};
export default NewRequestForm;
