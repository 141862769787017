import { useEffect, useCallback } from 'react';
import { Box, Pagination, PaginationItem, Typography } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';
import PreviousIcon from './PreviousIcon';
import NextIcon from './NextIcon';

export function CustomPagination({
  totalCount,
  setSelectedRows,
  setOffset,
  limit,
  pageCount,
  setCurrentPage,
  currentPage,
}) {
  const apiRef = useGridApiContext();

  const firstRowIndex = currentPage * limit;
  const lastRowIndex = Math.min(firstRowIndex + limit, totalCount);

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(apiRef.current.getSelectedRows());
    }
  }, [apiRef, setSelectedRows, currentPage, limit]);

  const handleChangePage = useCallback(
    (event, value) => {
      const newPage = value - 1;

      setCurrentPage(newPage);
      setOffset(newPage * limit);
      apiRef.current.setPage(newPage);
    },
    [setCurrentPage, setOffset, limit, apiRef]
  );

  return (
    <>
      {totalCount > 0 ? (
        <Box
          sx={{
            display: 'flex',
            border: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant='body'>
            Εμφανίζονται <strong>{firstRowIndex + 1}</strong> εώς{' '}
            <strong>{lastRowIndex}</strong> από <strong>{totalCount}</strong>{' '}
            αποτελέσματα
          </Typography>
          <Pagination
            page={currentPage + 1}
            count={pageCount}
            renderItem={(props2) => (
              <PaginationItem
                slots={{ previous: PreviousIcon, next: NextIcon }}
                {...props2}
                disableRipple
              />
            )}
            onChange={handleChangePage}
          />
        </Box>
      ) : (
        <Box></Box>
      )}{' '}
    </>
  );
}
