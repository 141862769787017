import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Details, { mapByRefValue } from '../../Details/Details/Details';
import useAuth from '../../Services/useAuth';
import { useGetRequestById } from '../../Services/useGetRequestById';
import Loader from '../../UIComponents/Loader';
import { communicationData, personalData } from '../../helperFuncs';
import RegistrationSuccess from './RegistrationSuccess';
import { useEffect, useState } from 'react';
import ProtectedRouteViolation from '../AuthPages/ProtectedRouteViolation';
import { downloadFile } from '../../Services/httpCalls';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { enqueueSnackbar } from 'notistack';

const RegistrationPreview = () => {
  const { id } = useParams();
  const [isLinkVisible, setIsLinkVisible] = useState(false);
  const { getRole, getUser } = useAuth();
  const location = useLocation();
  const { data: request, isLoading: isRequestLoading } = useGetRequestById(id);
  const mappedPersonalData =
    request &&
    mapByRefValue(
      {
        ...request?.data?.requests[0].user,
        amka: request?.data.requests[0].amka,
      },
      personalData
    );
  const mappedCommunicationData =
    request &&
    mapByRefValue(
      {
        ...request?.data.requests[0],
        email: request?.data.requests[0].user.email,
      },
      communicationData
    );
  useEffect(() => {
    // Delay the appearance of the Link by 4 seconds
    const timer = setTimeout(() => {
      setIsLinkVisible(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const fileDownload = async (request_id, profession_id, file_name) => {
    try {
      await downloadFile(request_id, profession_id, file_name);
    } catch (err) {
      enqueueSnackbar('Παρουσιάστηκε σφάλμα στο κατέβασμα του αρχείου', {
        variant: 'error',
        autoHideDuration: 4000,
      });
    }
  };
  const mappedProfesionalData = request?.data.requests && [
    {
      name: 'Χωροταξική δραστηριοποίηση',
      refValue: 'spatialActivity',
      id: 0,
      value: request?.data.requests[0]?.municipalities
        .map((item) => item.municipality)
        .join(', '),
    },
    ...request?.data.requests[0]?.professions.flatMap((item, index) => [
      {
        name: 'Ειδικότητα/ Επάγγελμα',
        refValue: 'job',
        id: index + 1,
        value: item.profession,
      },
      {
        name: 'Τεκμηρίωση επαγγέλματος/ειδικότητας',
        refValue: 'proof_description',
        id: index + 2,
        value: item.proof_description,
      },

      {
        name: 'Τίτλος σπουδών',
        refValue: 'profession_proof',
        id: index + 3,
        value:
          item.proof === 4 ? (
            isLinkVisible ? (
              <Typography
                component='a'
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() =>
                  fileDownload(
                    request.data.requests[0]?.id,
                    item?.profession_id,
                    item?.file?.name
                  )
                }
              >
                {item?.file?.name}
              </Typography>
            ) : (
              <Box sx={{ height: '50px' }}>
                <Loader />
              </Box>
            )
          ) : (
            'Δεν απαιτείται'
          ),
      },
    ]),
  ];

  const communicationGroupData =
    request &&
    request?.data.requests[0].communication_groups.map((item) => item.category);

  const UserCertification = () => {
    return (
      <Box>
        <Grid
          container
          display='flex'
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          className='grid-success-view'
        >
          <Grid item xs={6}>
            <Typography variant='h3'>Πιστοποιημένος χρήστης</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {request?.data.requests[0]?.user?.is_certified === 1 ? (
                <Typography>Ναι</Typography>
              ) : (
                <Link
                  style={{ textDecoration: 'none', color: 'blue' }}
                  to='/registration-form'
                >
                  Όχι
                </Link>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const UserStatus = () => {
    return (
      <Box>
        {getRole() === 'admin' ? (
          <Grid
            container
            display='flex'
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            className='grid-success-view'
            sx={{ my: 3 }}
          >
            <Grid item xs={6}>
              <Typography variant='h3'>Κατάσταση</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {request?.data.requests[0]?.user?.is_active === 1
                  ? 'Ενεργός'
                  : 'Ανενεργός'}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      {request?.data?.requests[0].user.id !== getUser()?.sub &&
      getRole() === 'user' &&
      !isRequestLoading ? (
        <ProtectedRouteViolation />
      ) : isRequestLoading ? (
        <Loader />
      ) : (
        <Box>
          <Box
            className={
              location.pathname.includes('success')
                ? 'registration-success'
                : ''
            }
          >
            {location.pathname.includes('success') && <RegistrationSuccess />}
          </Box>

          {getRole() !== 'admin' ? (
            <Typography sx={{ my: 3 }} className='typography-subtitle-success'>
              Εγγραφή στο μητρώο απασχολούμενων του πολιτιστικού και
              δημιουργικού τομέα
            </Typography>
          ) : null}

          <Typography
            variant='h1'
            sx={{ my: 3 }}
            className='typography-subtitle-success'
          >
            Προβολή
          </Typography>
          {isRequestLoading ? (
            <Loader />
          ) : (
            <Box>
              <UserCertification />
              <UserStatus />

              <Typography variant='h3' sx={{ my: 3 }}>
                Προσωπικά στοιχεία
              </Typography>
              <Details id={'personalData'} rows={mappedPersonalData} />
              <Typography variant='h3' sx={{ my: 3 }}>
                Στοιχεία επικοινωνίας
              </Typography>
              <Details rows={mappedCommunicationData} />
              <Typography variant='h3' sx={{ my: 3 }}>
                Στοιχεία Δραστηριοποίησης
              </Typography>
              <Details rows={mappedProfesionalData} />
              <Box sx={{ mb: 15 }}>
                <Typography variant='h3' sx={{ my: 3 }}>
                  Ομάδες επικοινωνίας
                </Typography>
                <Details
                  rows={[
                    {
                      id: 0,
                      name: 'Κατηγορία',
                      value: communicationGroupData
                        ? communicationGroupData
                        : 'Δεν ανήκετε σε κάποια ομάδα επικοινωνίας',
                    },
                  ]}
                />
              </Box>
            </Box>
          )}

          {getRole() !== 'admin' ? (
            <Box sx={{ mb: 4 }}>
              <GovButton
                ariaLabel={'Αποθήκευση'}
                type={'submit'}
                variant='contained'
                onClick={() => window.print()}
                background={'#006736'}
                hoverBackground={'#154a2b'}
                borderColor={'#006736'}
                startIcon={<PictureAsPdfIcon />}
              >
                Αποθήκευση
              </GovButton>
            </Box>
          ) : /*  <Button
              sx={{ mb: 4 }}
              onClick={() => window.print()}
              variant='contained'
              color={'success'}
              startIcon={<PictureAsPdfIcon />}
            >
              Αποθήκευση
            </Button> */
          null}
        </Box>
      )}
    </>
  );
};
export default RegistrationPreview;
