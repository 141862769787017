import AppModal from './Modals/AppModal';
import { Modal } from '@mui/material';
import { Box } from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

const style = {
  position: 'absolute',
  top: '6%',
  left: '30%',
};

const PDFViewer = (props) => {
  const { width } = useWindowSize();
  const isMobileView = width < 1023;
  const isIPadView = width >= 1024 && width <= 1224;
  const isLaptopView = width >= 1225 && width <= 1540;

  const iframeHeight = () => {
    let height = '700';
    if (isMobileView) {
      height = '300';
    } else if (isLaptopView) {
      height = '600';
    } else if (isIPadView) {
      height = '500';
    } else {
      height = '700';
    }

    return height;
  };
  return (
    <Modal
      open={props.open}
      onClose={() => props.setIsShownModal(false)}
      aria-labelledby='modal-pdf-viewer'
      aria-describedby='modal-pdf-viewer-description'
    >
      <Box sx={style}>
        <iframe
          src={`data:application/pdf;base64,${props.data}`}
          width='800'
          height={iframeHeight()}
          title='Old Registration File'
        />
      </Box>
    </Modal>
  );
};

export default PDFViewer;
