import { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { taxisRedirect } from '../../Services/taxisnet';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import CircleIcon from '@mui/icons-material/Circle';

const checkboxStyles = {
  '& .MuiSvgIcon-root': { fontSize: 28 },
};

const bulletStyle = {
  display: 'inline',
  fontSize: '10px',
  marginRight: '10px',
};

const BulletText = ({ children }) => {
  return (
    <Typography
      ml={4}
      mt={1}
      lineHeight={2}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <CircleIcon sx={bulletStyle} />
      {children}
    </Typography>
  );
};

const UserLoginConfirmationPage = () => {
  const [acceptances, setAcceptances] = useState({
    terms: false,
    policy: false,
  });

  const handleConfirm = () => {
    taxisRedirect();
  };

  const handleChangeAcceptances = (e) => {
    const { name, checked } = e.target;
    setAcceptances((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const isLoginDisabled = !(acceptances.terms && acceptances.policy);

  const terms = (
    <Typography>
      Δηλώνω ότι έχω διαβάσει και αποδέχομαι τους{' '}
      <a
        href={process.env.REACT_APP_TERMS_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        Όρους Χρήσης
      </a>
    </Typography>
  );

  const policy = (
    <Typography>
      Δηλώνω ότι έχω διαβάσει την{' '}
      <a
        href={process.env.REACT_APP_POLICY_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        {' '}
        Πολιτική Προστασίας Προσωπικών Δεδομένων
      </a>
      {'  '} και παρέχω τη συγκατάθεσή μου
    </Typography>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        mt: 1,
        mx: 8,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant='h2'>
        Συνδεθείτε κάνοντας χρήση των κωδικών taxisnet
      </Typography>
      <Typography>
        Για να εισέλθετε στο σύστημα, θα πρέπει να συνδεθείτε με τους
        προσωπικούς κωδικούς taxisnet (username & password)
      </Typography>
      Για το σκοπό αυτό, θα μεταφερθείτε προσωρινά στο ασφαλές περιβάλλον της
      Γενικής Γραμματείας Πληροφοριακών Συστημάτων Δημόσιας Διοίκησης για να
      κάνετε τη σύνδεση.
      <Typography textAlign={'justify'}>
        Το Υπουργείο Πολιτισμού συλλέγει και περαιτέρω επεξεργάζεται τα
        προσωπικά δεδομένα σας σύμφωνα με την εθνική και ενωσιακή νομοθεσία περί
        προστασίας προσωπικών δεδομένων και συγκεκριμένα σύμφωνα με το Γενικό
        Κανονισμό Προστασίας Δεδομένων (ΕΕ) 2016/679 (GDPR) και το ν. 4624/2019.
        Για περισσότερες πληροφορίες ως προς την προστασία των προσωπικών σας
        δεδομένων, παρακαλούμε να διαβάσετε την{' '}
        <a
          href={process.env.REACT_APP_POLICY_URL}
          target='_blank'
          rel='noopener noreferrer'
        >
          Πολιτική Προστασίας Δεδομένων Προσωπικού Χαρακτήρα
        </a>
        , διαθέσιμη στην ιστοσελίδα μας. Σε κάθε περίπτωση μπορείτε να
        απευθυνθείτε στον Υπεύθυνο Προστασίας Δεδομένων στο email dpo@culture.gr
      </Typography>
      <Box>
        <FormControlLabel
          control={<Checkbox />}
          id='terms'
          label={terms}
          name='terms'
          key='terms'
          sx={checkboxStyles}
          value={acceptances.terms}
          onChange={handleChangeAcceptances}
        />
        <FormControlLabel
          control={<Checkbox />}
          id='policy'
          label={policy}
          name='policy'
          key='policy'
          sx={checkboxStyles}
          value={acceptances.policy}
          onChange={handleChangeAcceptances}
        />
        <BulletText>
          για το άνοιγμα λογαριασμού στο Μητρώο Απασχολούμενων στον Πολιτισμό
        </BulletText>
        <BulletText>για τη διενέργεια ηλεκτρονικής επικοινωνίας</BulletText>
      </Box>
      <RoundedButton
        variant='contained'
        color='link'
        onClick={handleConfirm}
        disabled={isLoginDisabled}
      >
        Είσοδος με διαπιστευτήρια Taxisnet
      </RoundedButton>
    </Box>
  );
};

export default UserLoginConfirmationPage;
