import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { postNewQuestion } from './httpCalls';

export function usePostNewQuestion(data) {
  return useMutation({
    mutationKey: [`new_question`],
    mutationFn: async (body) => {
      return await postNewQuestion(data);
    },
    onSuccess: () => {
      /*  enqueueSnackbar('Επιτυχία', {
        variant: 'success',
        autoHideDuration: 2000,
      }); */
    },
    onError: (error) => {
      enqueueSnackbar(error.response?.data?.message[0] || 'Αποτυχία', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
  });
}
