import { useId } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { useWindowSize } from '@uidotdev/usehooks';

export default function AADEdetails({ aadeInfo }) {
  const { width } = useWindowSize();
  const isMobileView = width < 1023;
  const isIPadView = width >= 1024 && width <= 1224;
  const isLaptopView = width >= 1225 && width <= 1540;
  const randomId = useId();

  const gridColumnWidth = () => {
    let gridTemplateColumns = '550px 1fr';
    if (isMobileView) {
      gridTemplateColumns = '250px 1fr';
    } else if (isLaptopView) {
      gridTemplateColumns = '410px 1fr';
    } else if (isIPadView) {
      gridTemplateColumns = '210px 1fr';
    } else {
      gridTemplateColumns = '550px 1fr';
    }

    return gridTemplateColumns;
  };

  const getActivityKind = (kind) => {
    let activityKind = '';
    if (kind === '1') {
      activityKind = '[Κύρια]';
    } else if (kind === '2') {
      activityKind = '[Δευτερεύουσα]';
    } else if (kind === '3') {
      activityKind = '[Λοιπή]';
    } else if (kind === '4') {
      activityKind = '[Βοηθητική]';
    }
    return activityKind;
  };

  const DetailRow = ({ label, value }) => (
    <>
      <Box
        display='grid'
        gridTemplateColumns={gridColumnWidth()}
        alignItems='center'
        my={1}
      >
        <Typography fontWeight={600}>{label}</Typography>
        <Typography>{value}</Typography>
      </Box>
      <Divider />
    </>
  );

  return (
    <>
      {aadeInfo.length > 0 ? (
        <Box>
          {aadeInfo.map((info) => (
            <Box key={`${randomId} - ${info.register_date}`}>
              <DetailRow
                label={'Ημερομηνία Έναρξης'}
                value={info.register_date}
              />
              <DetailRow
                label={'Κύρια Δραστηριότητα'}
                value={info.main_activity_description}
              />
              {info.info_activities.length > 0 ? (
                <Box my={1}>
                  <Typography fontWeight={600}>Δραστηριότητες</Typography>
                  {info.info_activities.map((activity) => (
                    <Box key={`${randomId} - ${activity.activity_start_date}`}>
                      <Typography my={1}>
                        {`${activity.description} ${getActivityKind(
                          activity.activity_kind
                        )} από ${activity.activity_start_date}`}
                      </Typography>
                      <Divider />
                    </Box>
                  ))}
                </Box>
              ) : null}
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>Δεν βρέθηκαν τα στοιχεία της ΑΑΔΕ</Typography>
      )}
    </>
  );
}
