import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputLabel, Typography } from '@mui/material';
import { useState } from 'react';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import * as yup from 'yup';
import { useNewOtp } from '../../Services/useNewOtp';
import { useOtpConfirm } from '../../Services/useOtpConfirm';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import GovButton from '../../UIComponents/Buttons/GovButton';

const ConfirmationCode = () => {
  const { mutateAsync: requestNewOtp } = useNewOtp();
  const { mutateAsync: otpConfirm, isSuccess, isError, data } = useOtpConfirm();
  const [isNewOtpRequested, setIsNewOtpRequested] = useState(false);
  const [serverError, setServerError] = useState('');
  const formContext = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup.string().required('Το πεδίο είναι υποχρεωτικό'),
      })
    ),
    defaultValues: { otp: '' },
  });
  const handleNewOtpRequest = async () => {
    try {
      await requestNewOtp();
      setIsNewOtpRequested(true);
      setTimeout(() => {
        setIsNewOtpRequested(false);
      }, 10000); // 10 seconds
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormContainer
      formContext={formContext}
      onSuccess={async (data) => {
        try {
          await otpConfirm(data);
        } catch (error) {
          console.log(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            setServerError(error.response.data.message);
          } else {
            setServerError('Παρακαλώ δοκιμάστε αργότερα');
          }
        }
      }}
    >
      <Typography sx={{ mt: 8 }} variant='h1'>
        Εισαγωγή κωδικού επιβεβαίωσης
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, my: 5 }}>
        <Typography>
          Έχει αποσταλεί κωδικός επιβεβαίωσης με μήνυμα στο email. Παρακαλούμε
          συμπληρώστε τον κωδικό στο πεδίο που ακολουθεί
        </Typography>
        <Typography>
          Καταχωρήστε τον κωδικό αυτό στο παρακάτω πεδίο και πατήστε
          ''Επιβεβαίωση''
        </Typography>
        <Typography>
          Σε περίπτωση που δεν έφθασε ο κωδικός, ελέγξτε αν έχετε καταχωρήσει
          λάθος email και επιστρέψτε στο προηγούμενο βήμα για να δοκιμάσετε
          ξανά. Σε περίπτωση που συνεχίζετε να έχετε πρόβλημα, επικοινωνήστε
          μαζί μας.
        </Typography>
      </Box>
      <Box>
        <InputLabel htmlFor='otp'>{'Κωδικός επιβεβαίωσης'}</InputLabel>
        <TextFieldElement
          id='otp'
          sx={{ width: '30rem', mt: 1 }}
          InputProps={{ style: { borderRadius: '5px' } }}
          inputProps={{ style: { border: 0, padding: 8 } }}
          fullWidth
          name='otp'
        />
        {serverError && <p style={{ color: 'red' }}>{serverError}</p>}
        {/* {isNewOtpRequested && <p style={{color:'green'}}>Εκδόθηκε νέος κωδικός</p>} */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'flex-end',
          mb: 10,
          my: 4,
        }}
      >
        <GovButton
          ariaLabel={'Επιβεβαίωση'}
          type={'submit'}
          variant='contained'
          background={'#006736'}
          hoverBackground={'#154a2b'}
          borderColor={'#006736'}
        >
          Επιβεβαίωση
        </GovButton>
        <GovButton
          ariaLabel={'Έκδοση νέου κωδικού'}
          type={'button'}
          variant='outlined'
          background={'white'}
          hoverBackground={'white'}
          borderColor={'#006736'}
          color={'#006736'}
          onClick={handleNewOtpRequest}
        >
          Έκδοση νέου κωδικού
        </GovButton>
      </Box>
    </FormContainer>
  );
};
export default ConfirmationCode;
