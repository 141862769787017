import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../Services/useAuth';
import { jwtDecode } from 'jwt-decode';

const RedirectionPage = () => {
  const navigate = useNavigate();
  const { getRole, token } = useAuth();

  useEffect(() => {
    localStorage.removeItem('role');
    const initializeUser = () => {
      if (token) {
        const decodedUser = jwtDecode(token);
        if (decodedUser.afm) {
          const isActive = decodedUser?.is_active === 1;
          const isVerified = decodedUser?.is_email_verified === 1;

          if (getRole() === 'admin') {
            navigate('/request-management');
          } else if (isActive && getRole() === 'user' && isVerified) {
            navigate('/details-preview');
          } else if (isActive && getRole() === 'user' && !isVerified) {
            navigate('/email');
          } else if (!isActive && getRole() === 'user') {
            navigate('/inactive-user');
          } else {
            console.log('redirect');
          }
        }
      }
    };

    initializeUser();
  }, [token, navigate]);

  return <Typography>Γίνεται ανακατεύθυνση...</Typography>;
};

export default RedirectionPage;
