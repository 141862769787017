import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { updateRequest } from "./httpCalls";

export function useUpdateRequest(data, id) {
    return useMutation({
        mutationKey: [`update_request`],
        mutationFn: async () => {
            return await updateRequest(data,id)
        },
        onError: () => {
            enqueueSnackbar('Αποτυχία', { variant: 'error' , autoHideDuration:2000})
        }
    });
}
