import { Box, Typography } from "@mui/material";

const UnAuthorizedUserPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 3,
        mb: 10,
        gap: 4,
      }}
    >
      <Typography variant="h1">Απαιτείται ταυτοποίηση</Typography>
      <Typography variant="h3">Υπάρχει κάποιο προβλημα</Typography>
      <Typography>Σφάλμα κατά τη σύνδεσή σας.(access_denied)</Typography>

      <Typography>Για να προχωρήσετε, πρέπει πρώτα να συνδεθείτε</Typography>
      <Box></Box>
    </Box>
  );
};
export default UnAuthorizedUserPage;
