import { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../Services/useAuth';
import Loader from '../../UIComponents/Loader';
import { CustomPagination } from '../../UIComponents/CustomPagination';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { getQuestionByUserId } from '../../Services/httpCalls';

const QuestionsView = () => {
  const navigate = useNavigate();
  const { getUser } = useAuth();
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(0);

  const userId = getUser()?.sub;

  const pageCount = useMemo(() => {
    return Math.ceil(totalQuestions / LIMIT);
  }, [totalQuestions]);

  const getQuestions = async () => {
    const { data } = await getQuestionByUserId(userId, offset, LIMIT);
    setQuestions(data?.questions);
    setTotalQuestions(data?.total_questions);
    setIsLoading(false);
  };

  useEffect(() => {
    getQuestions();

    return () => {
      setQuestions([]);
    };
  }, [offset]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: 'flex',
            mb: 8,
            mt: 1,
            flexDirection: 'column',
            gap: 6,
          }}
        >
          <Typography variant='h1'>Τα ερωτήματα μου</Typography>
          {totalQuestions === 0 && (
            <Typography>
              Δεν έχετε καταθέσει κάποιο ερώτημα, εάν θέλετε να καταθέσετε νέο
              ερώτημα πιέστε εδω
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <GovButton
              ariaLabel={'Νέο ερώτημα'}
              type={'button'}
              variant='contained'
              background={'#003476'}
              borderColor={'#003476'}
              hoverBackground={'#02234e'}
              onClick={() => navigate('/new-question')}
            >
              Νέο ερώτημα
            </GovButton>
          </Box>
          <Box>
            {questions.length > 0 ? (
              <DataGrid
                initialState={{
                  pagination: { paginationModel: { pageSize: LIMIT, page: 0 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                pagination
                pageSize={LIMIT}
                slots={{ pagination: CustomPagination }}
                slotProps={{
                  pagination: {
                    totalCount: totalQuestions,
                    setOffset: setOffset,
                    limit: LIMIT,
                    pageCount: pageCount,
                    setCurrentPage: setCurrentPage,
                    currentPage: currentPage,
                  },
                }}
                disableRowSelectionOnClick
                columnHeaderHeight={1}
                sx={{
                  '&, [class^=MuiDataGrid]': {
                    border: 'none',
                    overflow: 'hidden',
                    justifyContent: 'flex-start',
                  },
                }}
                paginationMode='server'
                rows={questions}
                columns={[
                  {
                    field: 'request',
                    flex: 1,
                    headerName: '',
                    renderCell: (params) => (
                      <Box
                        key={params.id}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          marginTop: 1,
                        }}
                      >
                        <Box>
                          <Typography
                            component={Link}
                            to={`/question/${params.id}`}
                            sx={{
                              color: 'black.main',
                              px: 0.5,
                              fontWeight: 'bold',
                            }}
                          >
                            {`Θέμα: ${params.row.subject}`}
                          </Typography>
                          <Typography sx={{ color: 'gray.main' }}>
                            {`${params.row.creation_date} ${
                              params.row.creation_time
                            } ${
                              params.row.answer_date
                                ? 'Απαντήθηκε'
                                : 'Δεν απαντήθηκε'
                            }`}
                          </Typography>
                        </Box>
                      </Box>
                    ),
                  },
                ]}
              />
            ) : (
              <Typography>Δεν έχετε υποβάλει κάποιο ερώτημα.</Typography>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};
export default QuestionsView;
