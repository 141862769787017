import LoginIcon from '@mui/icons-material/Login';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { taxisAdminRedirect } from '../../Services/taxisnet';
import useAuth from '../../Services/useAuth';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import { enqueueSnackbar } from 'notistack';
import CircleIcon from '@mui/icons-material/Circle';
import InitialText from '../../UIComponents/InitialText';

const NoAuthorizedPage = () => {
  const navigate = useNavigate();
  const { role, setRole, setToken, token, logout } = useAuth();
  const [code, setCode] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (token) {
      setToken(token);
      navigate('/redirect');
    }
  }, [navigate, token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const codeParam = searchParams.get('code');

    if (token) {
      navigate('/redirect');
      return;
    }

    if (codeParam) {
      setCode(codeParam);
      setIsRedirecting(true);
    }
  }, [token, navigate]);

  useEffect(() => {
    let isMounted = true;

    const getUser = async () => {
      if (!code || !isMounted) return;

      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/login`,
          {
            code,
            is_admin: role === 'admin',
          }
        );

        if (isMounted) {
          setToken(resp.data.access_token);
          navigate('/redirect');
        }
      } catch (error) {
        console.error(error);

        if (
          error.response?.data?.statusCode === 403 ||
          error.response?.data?.statusCode === 400
        ) {
          enqueueSnackbar(error?.response?.data?.message, {
            variant: 'error',
            autoHideDuration: 2000,
          });
          setIsRedirecting(false);
          navigate('/');
        }
      }
    };

    if (code && isMounted) {
      getUser();
    }

    return () => {
      isMounted = false;
    };
  }, [code, role]);

  return (
    <>
      {isRedirecting ? (
        <Typography>Γινεται ανακατεύθυνση...</Typography>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 3,
              mb: 6,
              gap: 4,
            }}
          >
            <Typography fontSize='44px' fontWeight={'bold'}>
              Απαιτείται ταυτοποίηση
            </Typography>
            <Typography fontSize='18px'>
              Για να προχωρήσετε, πρέπει πρώτα να συνδεθείτε
            </Typography>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Button
                aria-label='user-login'
                startIcon={<LoginIcon sx={{ fontSize: '16px' }} />}
                onClick={() => {
                  setRole('user');
                  setToken(null);
                  navigate('/user-login-confirmation');
                }}
                variant={'contained'}
                sx={{
                  backgroundColor: '#006736',
                  color: 'white',
                  padding: '0.9rem 1.25rem',
                  borderColor: '#006736',
                  fontWeight: 500,
                  borderRadius: '5px',
                  fontSize: '16px',
                  width: 'inherit',
                  '&:hover': {
                    backgroundColor: '#154a2b',
                    color: 'white',
                  },
                }}
              >
                Σύνδεση χρήστη (Taxis)
              </Button>
              <Button
                aria-label='admin-login'
                startIcon={<LoginIcon sx={{ fontSize: '16px' }} />}
                onClick={() => {
                  setRole('admin');
                  setToken(null);
                  taxisAdminRedirect();
                  /*  window.location.href =
                    'https://test.gsis.gr/oauth2servergov/login.jsp'; */
                }}
                variant={'contained'}
                sx={{
                  backgroundColor: '#003476',
                  color: 'white',
                  padding: '0.9rem 1.25rem',
                  borderColor: '#003476',
                  fontWeight: 500,
                  borderRadius: '5px',
                  fontSize: '16px',
                  width: 'inherit',
                }}
              >
                Σύνδεση Admin
              </Button>
            </Box>
          </Box>

          <InitialText />
        </>
      )}
    </>
  );
};

export default NoAuthorizedPage;
