import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { updateUserCommunicationGroups } from './httpCalls';

export function useUpdateUserCommunicationGroups() {
  return useMutation({
    mutationKey: [`communicationGroups`],
    mutationFn: async (data) => {
      return await updateUserCommunicationGroups(data);
    },
    onSuccess: () => {
      enqueueSnackbar('Επιτυχής υποβολή', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.message || 'Αποτυχία', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    },
  });
}
