import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { uploadFile } from './httpCalls';

export function useUploadFile(file) {
  return useMutation({
    mutationKey: [`upload_file`],
    mutationFn: async (file) => {
      return await uploadFile(file);
    },
    onSuccess: () => {
      enqueueSnackbar('Επιτυχία', {
        variant: 'success',
        autoHideDuration: 2000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.response.data.message || 'Αποτυχία', {
        variant: 'error',
        autoHideDuration: 4000,
      });
      error.response.data.statusCode === 409 &&
        enqueueSnackbar(
          'Θα πρεπει πρώτα να διαγράψετε το αρχείο για να ανεβάσετε ενα νέο. ',
          { variant: 'error', autoHideDuration: 4000 }
        );
    },
  });
}
