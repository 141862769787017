import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputLabel, Typography } from '@mui/material';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { usePostEmail } from '../../Services/usePostEmail';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { isEmailAvailable } from '../../Services/httpCalls';
import useAuth from '../../Services/useAuth';
import { enqueueSnackbar } from 'notistack';

const EmailFill = () => {
  const { mutateAsync: postEmail, isSuccess } = usePostEmail();
  const { getUser } = useAuth();
  const navigate = useNavigate();
  const formContext = useForm({
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('Παρακαλώ πληκτρολογήστε έγκυρο email(example@gmail.com)')
          .required('Το πεδίο είναι υποχρεωτικό'),
      })
    ),
    defaultValues: { email: '' },
  });

  const checkEmailExistance = async (email) => {
    const emailExist = await isEmailAvailable({
      email: email,
      user_id: getUser()?.sub,
    });
    return emailExist.is_email_exist;
  };
  return (
    <FormContainer
      formContext={formContext}
      onSuccess={async (data) => {
        try {
          const isEmailExist = await checkEmailExistance(data.email);
          if (isEmailExist) {
            enqueueSnackbar(
              'To email που καταχωρήσατε χρησιμοποιείται από άλλο χρήστη. Βεβαιωθείτε οτι δηλώσατε το δικό σας email.',
              {
                variant: 'error',
                autoHideDuration: 6000,
              }
            );
            return;
          }
          await postEmail(data);
          if (isSuccess) {
            navigate('/confirmation-code');
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }}
    >
      <Typography sx={{ mt: 8 }} variant='h1'>
        Καταχωρίστε τη διεύθυνση ηλεκτρονικού ταχυδρομείου (email) στην οποία θα
        λαμβάνετε την επίσημη επικοινωνία από το πρόγραμμα
      </Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          my: 4,
          pl: 2,
          height: '50px',
          borderLeft: '2px solid',
          borderColor: 'border.main',
        }}
      >
        <Typography sx={{ my: 4 }}>
          Η επίσημη επικοινωνία του προγράμματος θα αποστέλλεται σε αυτή την
          ηλεκτρονική διεύθυνση.
        </Typography>
      </Box>
      <Box>
        <InputLabel htmlFor='email'>
          {'Διεύθυνση ηλεκτρονικού ταχυδρομείου (email)'}
        </InputLabel>
        <TextFieldElement
          id='email'
          aria-label='Διεύθυνση ηλεκτρονικού ταχυδρομείου'
          sx={{ width: '30rem', mt: 1 }}
          InputProps={{ style: { borderRadius: '5px' } }}
          inputProps={{ style: { border: 0, padding: 8 } }}
          type='email'
          fullWidth
          name='email'
        />
      </Box>
      <Box sx={{ my: 4, mb: 10 }}>
        <GovButton
          ariaLabel={'Συνέχεια'}
          type={'submit'}
          variant='contained'
          background={'#006736'}
          hoverBackground={'#154a2b'}
          borderColor={'#006736'}
        >
          Συνέχεια
        </GovButton>
      </Box>
    </FormContainer>
  );
};
export default EmailFill;
