export const taxisRedirect = async () => {
  const queryParams = new URLSearchParams({
    client_id: process.env.REACT_APP_PUBLIC_OAUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_URI,
    response_type: 'code',
    scope: 'read',
    // state: nonce,
  }).toString();

  window.location.href = `${process.env.REACT_APP_PUBLIC_OAUTH_URL}?${queryParams}`;
};

export const taxisAdminRedirect = async () => {
  const queryParams = new URLSearchParams({
    client_id: process.env.REACT_APP_PUBLIC_OAUTH_CLIENT_ADMIN_ID,
    redirect_uri: process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_URI,
    response_type: 'code',
    scope: 'read',
    // state: nonce,
  }).toString();

  window.location.href = `${process.env.REACT_APP_PUBLIC_OAUTH_ADMIN_URL}?${queryParams}`;
};

export const taxisLogout = () => {
  try {
    window.location.href = `${process.env.REACT_APP_PUBLIC_OAUTH_LOGOUT_URL}/${process.env.REACT_APP_PUBLIC_OAUTH_CLIENT_ID}/?url=${process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_URI}`;
  } catch (error) {
    console.log(error);
    window.location.href = '/';
  }
};

export const taxisAdminLogout = () => {
  try {
    window.location.href = `${process.env.REACT_APP_PUBLIC_OAUTH_LOGOUT_ADMIN_URL}/${process.env.REACT_APP_PUBLIC_OAUTH_CLIENT_ADMIN_ID}/?url=${process.env.REACT_APP_PUBLIC_OAUTH_REDIRECT_URI}`;
  } catch (error) {
    console.log(error);
    window.location.href = '/';
  }
};
