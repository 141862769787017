import { Box, Typography } from '@mui/material';
import RoundedButton from '../../UIComponents/Buttons/RoundedButton';
import CardLayout from '../../UIComponents/CardLayout';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../../Services/useAuth';
import { getRequestByAfm, downloadStaticFile } from '../../Services/httpCalls';
import { jwtDecode } from 'jwt-decode';
import styles from '../../UIComponents/customStyles.css';
import GovButton from '../../UIComponents/Buttons/GovButton';

const RegistrationForm = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [isNewUser, setIsNewUser] = useState(null);

  useEffect(() => {
    const checkIsNewUser = async () => {
      if (!token) return false;
      try {
        const resp = await getRequestByAfm(jwtDecode(token)?.afm);
        setIsNewUser(!(resp?.data?.requests?.length > 0));
      } catch (error) {
        console.error('Invalid token:', error);
        return false;
      }
    };
    checkIsNewUser();
  }, []);

  const downloadStaticKad = async () => {
    try {
      await downloadStaticFile('kad.pdf');
    } catch (err) {}
  };

  const downloadStaticSpecialtyCodes = async () => {
    try {
      await downloadStaticFile('ergani_specialty_codes.pdf');
    } catch (err) {}
  };

  return (
    <Box sx={{ my: 3 }}>
      <Typography variant='h2' sx={{ my: 2 }}>
        Οι κωδικοί σύνδεσης στο Taxisnet που χρησιμοποιήσατε δεν αντιστοιχούν σε
        πιστοποιημένο χρήστη.
      </Typography>
      <Typography>Πιστοποιημένος χρήστης θεωρείται ο</Typography>
      <Typography component='div'>
        <ul>
          <li style={{ paddingBottom: '20px' }}>
            Εργαζόμενος στον πολιτιστικό και δημιουργικό τομέα όπου είναι το
            φυσικό πρόσωπο που έχει παράσχει εργασία με σύμβαση ή σχέση
            εξαρτημένης εργασίας, που αποδεικνύεται από μία (1) τουλάχιστον
            ημέρα απασχόλησης, σύμφωνα με τα στοιχεία που αντλούνται από το
            Πληροφοριακό Σύστημα ΕΡΓΑΝΗ ΙΙ σε{' '}
            <span
              className='hoverable-text'
              onClick={downloadStaticSpecialtyCodes}
            >
              κωδικό ειδικότητας
            </span>{' '}
            που εμπίπτει στον πολιτιστικό και δημιουργικό τομέα.
          </li>
          <li style={{ paddingBottom: '20px' }}>
            Επαγγελματίας του πολιτιστικού και δημιουργικού τομέα είναι το
            φυσικό πρόσωπο που δραστηριοποιείται σε έναν από τους{' '}
            <span className='hoverable-text' onClick={downloadStaticKad}>
              Κωδικούς Αριθμούς Δραστηριότητας (ΚΑΔ)
            </span>{' '}
            του πολιτιστικού και δημιουργικού τομέα με ενεργό ΚΑΔ.
          </li>
          <li style={{ paddingBottom: '20px' }}>
            Εργαζόμενος σε υποστηρικτικές δραστηριότητες του πολιτιστικού και
            δημιουργικού τομέα είναι το φυσικό πρόσωπο που έχει παράσχει εργασία
            με σύμβαση ή σχέση εξαρτημένης εργασίας, που αποδεικνύεται από μία
            (1)τουλάχιστον ημέρα απασχόλησης σύμφωνα με τα στοιχεία που
            αντλούνται από το Π.Σ. ΕΡΓΑΝΗ ΙΙ σε{' '}
            <span
              className='hoverable-text'
              onClick={downloadStaticSpecialtyCodes}
            >
              κωδικό ειδικότητας
            </span>{' '}
            που αποτελεί υποστηρικτική δραστηριότητα του πολιτιστικού και
            δημιουργικού τομέα.
          </li>
          <li style={{ paddingBottom: '20px' }}>
            Επαγγελματίας υποστηρικτικών δραστηριοτήτων του πολιτιστικού και
            δημιουργικού τομέα είναι το φυσικό πρόσωπο που δραστηριοποιείται σε
            έναν από τους ΚΑΔ υποστηρικτικών δραστηριοτήτων του πολιτιστικού και
            δημιουργικού τομέα με ενεργό ΚΑΔ.
          </li>
        </ul>
      </Typography>
      <CardLayout title='Προσοχή' type='shadow'>
        <Typography sx={{ mb: 6 }}>
          Μπορείτε να χρησιμοποίησετε την εφαρμογή για να δείτε τα στοιχεία
          υπάρχουσας αίτησης ή να δημιουργήσετε νέα αίτηση εγγραφής στο μητρώο
          απασχολούμενων του πολιτιστικού και δημιουργικού τομέα ως μη
          πιστοποιημένος χρήστης.
        </Typography>
      </CardLayout>
      <Box sx={{ mt: 4 }}>
        <GovButton
          ariaLabel={'Συνέχεια'}
          type={'submit'}
          variant='contained'
          background={'#006736'}
          hoverBackground={'#154a2b'}
          borderColor={'#006736'}
          onClick={() =>
            isNewUser ? navigate('/request') : navigate('/registry-review')
          }
        >
          Συνέχεια
        </GovButton>
      </Box>
    </Box>
  );
};

export default RegistrationForm;
