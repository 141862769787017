import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
  }, []);
  return (
    <>
      <Box
        sx={{
          maxWidth: '42rem',
          fontSize: '3rem',
          lineHeight: '1.25',
          mh: '3rem',
          fontWeight: '700',
        }}
      >
        H σελίδα αυτή δεν είναι διαθέσιμη ή δεν υπάρχει.
      </Box>
    </>
  );
};
export default ErrorPage;
