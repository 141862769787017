import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

export default function InitialText() {
  const descriptionFontSize = '18px';
  const descriptionLineHeight = '34px';
  const bulletStyle = {
    display: 'inline',
    fontSize: '10px',
    marginRight: '10px',
  };
  return (
    <Box mb={4}>
      <Typography
        variant='body1'
        fontSize={descriptionFontSize}
        lineHeight={descriptionLineHeight}
        mb={1.5}
      >
        Αν είστε επαγγελματίας στον πολιτιστικό και δημιουργικό τομέα, μπορείτε
        να εγγραφείτε στο Μητρώο για να:
      </Typography>
      <Typography
        fontSize={descriptionFontSize}
        lineHeight={descriptionLineHeight}
        ml={2}
        mb={0.5}
      >
        <CircleIcon sx={bulletStyle} />
        Ενημερώνεστε για τη συνολική εικόνα δραστηριοποίησης, εργασίας και
        ασφάλισης σας στον πολιτιστικό και δημιουργικό τομέα.
      </Typography>
      <Typography
        fontSize={descriptionFontSize}
        lineHeight={descriptionLineHeight}
        ml={2}
      >
        <CircleIcon sx={bulletStyle} />
        Ενημερώνεστε για τα ισχύοντα δικαιώματα, παροχές, εργαλεία, πολιτικές
        και μέτρα στήριξης με προσωποποιημένη πληροφόρηση με βάση τα στοιχεία
        που έχουν αντληθεί και υφίστανται στο Μητρώο, καθώς και για σχετικές με
        τα ανωτέρω χρηστικές πληροφορίες, προθεσμίες και λοιπούς όρους υποβολής
        αιτημάτων.
      </Typography>
      <Typography
        fontSize={descriptionFontSize}
        lineHeight={descriptionLineHeight}
        ml={2}
      >
        <CircleIcon sx={bulletStyle} />
        Λαμβάνετε ηλεκτρονικά αντίγραφα του συνόλου των στοιχείων του που έχουν
        καταχωρηθεί στον λογαριασμό σας, καθώς και οποιοδήποτε πληροφοριακό ή
        άλλο υλικό είναι διαθέσιμο.
      </Typography>
    </Box>
  );
}
