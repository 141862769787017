import React, { useEffect, useMemo } from 'react';
import { taxisAdminLogout, taxisLogout } from '../../Services/taxisnet';
import useAuth from '../../Services/useAuth';
import { useNavigate } from 'react-router-dom';

const RedirectHandler = () => {
  const { getRole, logout } = useAuth();
  const navigate = useNavigate();

  const role = getRole();
  const logoutTaxisOauth = () => {
    return role === 'admin' ? taxisAdminLogout : taxisLogout;
  };

  const handleLogout = async () => {
    try {
      await logout();

      logoutTaxisOauth();

      // Navigate to root as a fallback (may not execute if redirect occurs)
      navigate('/');
    } catch (error) {
      console.log(error);
      navigate('/');
    }
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <div>
      <h1>Έξοδος...</h1>
      <p>Θα ανακατευθυνθείτε σύντομα.</p>
    </div>
  );
};

export default RedirectHandler;
