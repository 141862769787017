import { Button } from "@mui/material";

const RoundedButton = ({ color, variant, children, ...rest }) => {
    const textColor = variant === 'contained' ? 'white' : (theme) => theme.palette[color]['main']
    const bgColor = variant === 'contained' ? (theme) => theme.palette[color]['main'] : 'transparent'
    const borderColor = variant === 'text' ? 'transparent' : (theme) => theme.palette[color]['main']
    return (
        <Button
            {...rest}
            variant={variant}
            sx={{
                ...rest.sx,
                color: textColor,
                bgcolor: bgColor,
                '&:hover': {
                    backgroundColor: (theme) => theme.palette[color]['hover'],
                    color: 'white'
                },
                borderRadius: 1,
                borderColor: borderColor
            }}
        >
            {children}
        </Button>
    )
};

export default RoundedButton;
