import { Box, Paper, Typography } from '@mui/material';
import React from 'react';


function CardLayout(props) {
  const { title, children, type='simple' } = props;
  return (<>
    <Box mt={5} >
      <Paper variant='outlined'
        sx={{ display: 'flex', flexDirection: 'column', gap: '1rem', p: '16px', 
        height: 'fit-content', width: 'fit-content', borderRadius:0, 
        boxShadow: type === 'shadow' ?  3 : 0,
        border: '1px solid', borderColor:  type === 'shadow' ? 'primary.main': 'border.main', backgroundColor: 'transparent' }}>
        <Typography variant='h3'>{title}</Typography>
        <Box>
          {children}
        </Box>
      </Paper>
    </Box>
  </>);
}

export default CardLayout;