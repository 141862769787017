import { Box, Typography } from '@mui/material';
import { CheckboxElement } from 'react-hook-form-mui';
import { useGetActiveGroups } from '../Services/useGetActiveGroups';

const CommunicationGroups = () => {
  const { data: activeGroups } = useGetActiveGroups();
  return (
    <>
      <Typography variant='h3' sx={{ my: 2 }}>
        {' '}
        Θέλετε να εγγραφείτε στις ομάδες επικοινωνιας?
      </Typography>
      <Typography variant='h4' sx={{ color: 'gray.main', my: 2 }}>
        {' '}
        Αν ανήκετε σε παραπάνω από μία ομάδες, επιλέξτε ότι ισχύει
      </Typography>
      {activeGroups && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {activeGroups?.data?.categories?.map((item, index) => {
            return (
              <CheckboxElement
                label={item.category}
                name={item.id.toString()}
                key={item.id}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default CommunicationGroups;
