import {
  Container,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  activateUser,
  deactivateUser,
  editAdmin,
  getAdmins,
  isAfmAvailable,
  isEmailAvailable,
  postAdmin,
} from '../../Services/httpCalls';
import { DataGrid } from '@mui/x-data-grid';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CreateAdminModal from '../../UIComponents/Modals/CreateAdminModal';
import GovButton from '../../UIComponents/Buttons/GovButton';
import { enqueueSnackbar } from 'notistack';
import EditAdminModal from '../../UIComponents/Modals/EditAdminModal';
import { useWindowSize } from '@uidotdev/usehooks';
import useAuth from '../../Services/useAuth';

export default function AdminsManagement() {
  const { getUser } = useAuth();
  const [admins, setAdmins] = useState([]);
  const [openNewAdminModal, setOpenNewAdminModal] = useState(false);
  const [openEditAdminModal, setOpenEditAdminModal] = useState(false);
  const [editDefaultValues, setEditDefaultValues] = useState({
    first_name: '',
    last_name: '',
    afm: '',
    fathers_name: '',
    mothers_name: '',
    email: '',
  });
  const [editUserId, setEditUserId] = useState('');

  const handleOpenNewAdmin = () => {
    setOpenNewAdminModal(true);
  };

  const handleCloseNewAdmin = () => {
    setOpenNewAdminModal(false);
  };

  const handleOpenEditAdmin = () => {
    setOpenEditAdminModal(true);
  };

  const handleCloseEditAdmin = () => {
    setOpenEditAdminModal(false);
  };

  const getAdminData = async () => {
    const result = await getAdmins();
    setAdmins(result.admins);
  };

  const activateAdmin = async (user_id) => {
    try {
      await activateUser(user_id);
      await getAdminData();
      enqueueSnackbar('Ο διαχειριστής ενεργοποιήθηκε', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar('Παρουσιάστηκε κάποιο σφάλμα', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const deactivateAdmin = async (user_id) => {
    try {
      if (user_id === getUser().sub) {
        enqueueSnackbar(
          'Ο χρήστης ανήκει σε εσάς και δεν μπορείτε να τον απενεργοποιήσετε',
          {
            variant: 'error',
            autoHideDuration: 5000,
          }
        );
        return;
      }
      await deactivateUser(user_id);
      await getAdminData();
      enqueueSnackbar('Ο διαχειριστής απενεργοποιήθηκε', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } catch (err) {
      enqueueSnackbar('Παρουσιάστηκε κάποιο σφάλμα', {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const checkEmailExist = async (email, user_id) => {
    const emailRes = await isEmailAvailable({
      email: email,
      user_id: user_id,
    });
    return emailRes.is_email_exist;
  };

  const checkAfmExist = async (afm, user_id) => {
    const afmRes = await isAfmAvailable({
      afm: afm,
      user_id: user_id,
    });
    return afmRes.is_afm_exist;
  };

  const submitNewAdmin = async (data) => {
    try {
      const isEmailExist = await checkEmailExist(data.email, getUser()?.sub);
      const isAfmExist = await checkAfmExist(data.afm, getUser()?.sub);
      if (isEmailExist) {
        handleCloseNewAdmin();
        enqueueSnackbar('To email χρησιμοποιείται από άλλο χρήστη', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      if (isAfmExist) {
        handleCloseNewAdmin();
        enqueueSnackbar('To ΑΦΜ χρησιμοποιείται από άλλο χρήστη', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      await postAdmin(data);
      handleCloseNewAdmin();
      await getAdminData();
      enqueueSnackbar('Ο διαχειριστής καταχωρήθηκε!', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    } catch (err) {
      handleCloseNewAdmin();
      let message = 'Παρουσιάστηκε κάποιο σφάλμα';
      if (err && err.response.status === 400) {
        message = err.response.data.message;
      }
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  const handleEditUser = (data, user_id) => {
    setEditUserId(user_id);
    setEditDefaultValues({
      first_name: data.first_name,
      last_name: data.last_name,
      afm: data.afm,
      fathers_name: data.fathers_name,
      mothers_name: data.mothers_name,
      email: data.email,
      is_super_admin: data.is_super_admin,
    });
    handleOpenEditAdmin();
  };

  const submitEditAdmin = async (data) => {
    try {
      const isEmailExist = await checkEmailExist(data.email, editUserId);
      const isAfmExist = await checkAfmExist(data.afm, editUserId);
      if (isEmailExist) {
        handleCloseNewAdmin();
        enqueueSnackbar('To email χρησιμοποιείται από άλλο χρήστη', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      if (isAfmExist) {
        handleCloseNewAdmin();
        enqueueSnackbar('To ΑΦΜ χρησιμοποιείται από άλλο χρήστη', {
          variant: 'error',
          autoHideDuration: 5000,
        });
        return;
      }

      await editAdmin(data, editUserId);
      handleCloseEditAdmin();
      await getAdminData();
      enqueueSnackbar('H επεξεργασία ολοκληρώθηκε!', {
        variant: 'success',
        autoHideDuration: 5000,
      });
    } catch (err) {
      handleCloseEditAdmin();
      let message = 'Παρουσιάστηκε κάποιο σφάλμα';
      if (err && err.response.status === 400) {
        message = err.response.data.message;
      }
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    }
  };

  useEffect(() => {
    getAdminData();

    return () => {
      setAdmins([]);
    };
  }, []);

  const columns = [
    { field: 'afm', headerName: 'ΑΦΜ', flex: 0.8 },
    {
      field: 'first_name',
      headerName: 'Ονομα',
      flex: 0.8,
    },
    {
      field: 'last_name',
      headerName: 'Επώνυμο',
      flex: 0.8,
    },
    { field: 'email', headerName: 'Email', flex: 0.8 },
    { field: 'fathers_name', headerName: 'Ονομα Πατέρα', flex: 0.8 },
    { field: 'mothers_name', headerName: 'Ονομα Μητέρας', flex: 0.8 },
    {
      field: 'is_super_admin',
      headerName: 'Super Admin',
      flex: 0.8,
      renderCell: (params) =>
        params.row.is_super_admin === 1 ? (
          <Typography>Ναι</Typography>
        ) : (
          <Typography>Οχι</Typography>
        ),
    },
    {
      headerName: '',
      flex: 0.8,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => handleEditUser(params.row, params.row.id)}
            sx={{
              cursor: 'pointer',
              color: 'primary.dark',
              transition: 'background-color 0.3s, color 0.3s',
              padding: '12px',
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: 'grey.300',
              },
            }}
            aria-label='edit user'
          >
            <BorderColorIcon />
          </IconButton>
          {params.row.is_active === 1 ? (
            <IconButton
              sx={{ cursor: 'pointer' }}
              onClick={() => deactivateAdmin(params.row.id)}
            >
              <ToggleOffIcon fontSize='large' color='success' />
            </IconButton>
          ) : (
            <IconButton
              sx={{ cursor: 'pointer' }}
              onClick={() => activateAdmin(params.row.id)}
            >
              <ToggleOnIcon color='error' fontSize='large' />
            </IconButton>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Container maxWidth='xl' sx={{ marginBottom: 2 }}>
        <Typography variant='h1'>Διαχειριστές</Typography>
        <Typography variant='body1' lineHeight={5}>
          Διαχειριστείτε τους εσωτερικούς χρήστες της εφαρμογής
        </Typography>

        <Box sx={{ my: 3 }} display='flex' justifyContent='flex-end'>
          <GovButton
            background={'#006736'}
            hoverBackground={'#154a2b'}
            borderColor={'#006736'}
            onClick={handleOpenNewAdmin}
            ariaLabel={'admin-add'}
            type={'button'}
            color={'white'}
            variant={'contained'}
          >
            Δημιουργία διαχειριστή
          </GovButton>
        </Box>

        <Box>
          {admins.length > 0 ? (
            <DataGrid
              sx={{
                '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                  outline: 'none !important',
                },
                pb: 3,
                '&.MuiDataGrid-root': {
                  border: 'none',
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black',
                  borderRadius: 0,
                },
              }}
              // onCellClick={handleCellClick}
              rows={admins}
              columns={columns}
              disableColumnMenu
              disableRowSelectionOnClick
              disableColumnFilter
              hideFooter
            />
          ) : null}
        </Box>
      </Container>

      <CreateAdminModal
        openNewAdminModal={openNewAdminModal}
        handleCloseNewAdmin={handleCloseNewAdmin}
        submitNewAdmin={submitNewAdmin}
      />

      <EditAdminModal
        openEditAdminModal={openEditAdminModal}
        handleCloseEditAdmin={handleCloseEditAdmin}
        submitEditAdmin={submitEditAdmin}
        editDefaultValues={editDefaultValues}
        editUserId={editUserId}
      />
    </>
  );
}
