import ReactDOM from "react-dom";

import styles from "./AppModal.module.css";

const Backdrop = (props) => {
  return <div className={styles.backdrop} onClick={props.onHideModal} />;
};

const ModalOverlay = (props) => {
  let modalClasses = styles.modal;

props.overlay ?
    modalClasses += ` ${styles['modal-small']}`:
    modalClasses += ` ${styles['modal-large']}`
  
  return (
    <div className={modalClasses}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

const portalElement = document.getElementById("overlay");

const AppModal = ({overlay=true, onHideModal, children}) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onHideModal={onHideModal} />,
        portalElement
      )}
      {ReactDOM.createPortal(
          <ModalOverlay overlay={overlay}>{children}</ModalOverlay>,
        
        portalElement
      )}
    </>
  );
};

export default AppModal;
