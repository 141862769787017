import AddIcon from '@mui/icons-material/Add';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  marginBottom: '20px',
  '& .MuiAccordionSummary-root': {
    padding: '0px',
  },
  '.MuiPaper-elevation': {
    border: ' none !important',
  },
  '&.MuiAccordion-root': {
    '&::before': {
      backgroundColor: 'transparent',
    },
    boxShadow: 'none',
  },
}));

export default function AppAccordion(props) {
  const { id, title, details, onChange } = props;

  return (
    <CustomAccordion onChange={onChange} disableGutters>
      <AccordionSummary expandIcon={<AddIcon color='black' />} id={id}>
        <Typography
          variant='h3'
          sx={{
            fontWeight: 'bold',
            boxShadow: 'none',
            padding: 0,
            marginBottom: 2,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>{details}</AccordionDetails>
    </CustomAccordion>
  );
}
