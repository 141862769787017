// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppModal_backdrop__TC-jp {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .AppModal_modal__KlAQ2 {
    position: fixed;
    display: flex;
    justify-content: center;
    top: 20vh;
    left: 5%;
    width: 90%;
    background-color: white;
    padding: 1rem;
    border-radius: 14px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: AppModal_slide-down__5cMv- 300ms ease-out forwards;
  }
  
  .AppModal_modal-small__qNXYS {
    width: 27rem;
    left: calc(50% - 10rem);
  }
  
  .AppModal_modal-large__XRDmn {
    background-color: transparent;
    width: 40rem;
    left: calc(50% - 20rem);
  }
  @keyframes AppModal_slide-down__5cMv- {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }`, "",{"version":3,"sources":["webpack://./src/UIComponents/Modals/AppModal.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,WAAW;IACX,qCAAqC;EACvC;;EAEA;IACE,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,SAAS;IACT,QAAQ;IACR,UAAU;IACV,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,WAAW;IACX,6DAA6C;EAC/C;;EAEA;IACE,YAAY;IACZ,uBAAuB;EACzB;;EAEA;IACE,6BAA6B;IAC7B,YAAY;IACZ,uBAAuB;EACzB;EACA;IACE;MACE,UAAU;MACV,4BAA4B;IAC9B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF","sourcesContent":[".backdrop {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100vh;\r\n    z-index: 20;\r\n    background-color: rgba(0, 0, 0, 0.75);\r\n  }\r\n  \r\n  .modal {\r\n    position: fixed;\r\n    display: flex;\r\n    justify-content: center;\r\n    top: 20vh;\r\n    left: 5%;\r\n    width: 90%;\r\n    background-color: white;\r\n    padding: 1rem;\r\n    border-radius: 14px;\r\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);\r\n    z-index: 30;\r\n    animation: slide-down 300ms ease-out forwards;\r\n  }\r\n  \r\n  .modal-small {\r\n    width: 27rem;\r\n    left: calc(50% - 10rem);\r\n  }\r\n  \r\n  .modal-large {\r\n    background-color: transparent;\r\n    width: 40rem;\r\n    left: calc(50% - 20rem);\r\n  }\r\n  @keyframes slide-down {\r\n    from {\r\n      opacity: 0;\r\n      transform: translateY(-3rem);\r\n    }\r\n    to {\r\n      opacity: 1;\r\n      transform: translateY(0);\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `AppModal_backdrop__TC-jp`,
	"modal": `AppModal_modal__KlAQ2`,
	"slide-down": `AppModal_slide-down__5cMv-`,
	"modal-small": `AppModal_modal-small__qNXYS`,
	"modal-large": `AppModal_modal-large__XRDmn`
};
export default ___CSS_LOADER_EXPORT___;
